import { authService } from '@/services/auth.service';
import { redirectRouter, notifyMessage, handleError } from '@/helper';
import * as pageRouter from '@/constants/pageRouter';
import i18n from '@/plugins/i18n';

const initialState = {
  data: null,
  isLoading: false,
};

export const auth = {
  namespaced: true,
  state: initialState,
  getters: {
    data: (state) => state.data,
    isLoadingAuth: (state) => state.isLoading,
  },
  mutations: {
    loading(state) {
      state.isLoading = true;
    },
    loadingSuccess(state) {
      state.isLoading = false;
    },
  },
  actions: {
    async loadProfile({ commit }) {
      commit('loading');

      return await authService
        .profile()
        .then((payload) => {
          return Promise.resolve(payload);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {
          commit('loadingSuccess');
        });
    },
    async logout() {
      await authService.logout();
    },

    async changePassword({ commit }, payload) {
      commit('loading');

      return await authService
        .changePassword(payload)
        .then(() => {
          notifyMessage(
            i18n.t('screenChangePassword.changeSuccess'),
            'success'
          );
          redirectRouter(pageRouter.EMPLOYEE);
        })
        .catch((error) => {
          handleError(error);
        })
        .finally(() => {
          commit('loadingSuccess');
        });
    },
  },
};
