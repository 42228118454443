import { BaseService } from './base.service';

class UserService extends BaseService {
  async add(payload) {
    const { data } = await this.axios.post('/api/admins', payload);

    return data;
  }

  async getUserList(params) {
    const { data } = await this.axios.get('/api/admins', { params });
    return data;
  }

  async getUserDetail(id) {
    const { data } = await this.axios.get(`/api/admins/${id}`);

    return data.data;
  }

  async getUserHistory(id, params) {
    const { data } = await this.axios.get(`/api/admins/${id}/history`, {
      params,
    });

    return data;
  }

  async getInfoUser() {
    const { data } = await this.axios.get('/api/admins/info');

    return data.data;
  }

  async edit(payload, id) {
    const { data } = await this.axios.put(`/api/admins/${id}`, payload);

    return data;
  }

  async delete(id) {
    const { data } = await this.axios.delete(`/api/admins/${id}`);

    return data;
  }
}

export const userService = new UserService();
