export const LIMIT = 30;
export const PAGE = 1;
export const Unauthorized = 401;
export const ServerError = 500;
export const BadRequest = 400;

export const NAME_MAX_LENGTH = 30;
export const NAME_DATA_LENGTH = 255;
export const ADDRESS_MAX_LENGTH = 100;
export const ROLE_MAX_LENGTH = 20;
export const LIMIT_SELECT = 5;
