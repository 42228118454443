<template>
  <td
    class="text-sm leading-5 ptb-12 td-data"
    :class="fixed ? `fixed-${fixed}-column` : ''"
  >
    <div class="column-cell">
      <span>
        <slot />
      </span>
    </div>
  </td>
</template>

<script>
export default {
  name: 'TableCell',
  props: {
    fixed: {
      type: String,
      require: false,
    },
  },
};
</script>
