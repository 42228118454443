exports.LOGIN = 'LOGIN';
exports.CHANGE_PASSWORD = 'CHANGE_PASSWORD';
exports.EMPLOYEE = 'EMPLOYEE';
exports.EMPLOYEE_DETAIL = 'EMPLOYEE_DETAIL';
exports.STORE = 'STORE';
exports.STORE_DETAIL = 'STORE_DETAIL';
exports.PRODUCT = 'PRODUCT';
exports.PRODUCT_HISTORY = 'PRODUCT_HISTORY';
exports.MASTER_DATA = 'MASTER_DATA';
exports.SETTING = 'SETTING';
exports.CATEGORY = 'CATEGORY';
exports.SUB_CATEGORY = 'SUB_CATEGORY';
exports.HOME = 'HOME';
exports.USER = 'USER';
exports.USER_HISTORY_DETAIL = 'USER_HISTORY_DETAIL';
exports.PRODUCT_HISTORY = 'PRODUCT_HISTORY';
exports.PRODUCT_HISTORY_DETAIL = 'PRODUCT_HISTORY_DETAIL';

exports.LAYOUT = 'LAYOUT';
