import { extend } from 'vee-validate';
import i18n from '@/plugins/i18n';

import { NAME_DATA_LENGTH } from '@/constants/common';
import {
  DECIMAL_NUMBER,
  NEGATIVE_NUMBER,
  REGEX_NUMBER,
  REGEX_SPECIAL_CHARACTER,
} from '@/constants/regex';

extend('required', {
  validate: (value) => !!value,
  message: i18n.t('validate.require'),
});

extend('nameDataMax', {
  validate: (value) => value.length < NAME_DATA_LENGTH,
  message: i18n.t('validate.max255'),
});

extend('negativeNumber', {
  validate: (value) => !NEGATIVE_NUMBER.test(value),
  message: i18n.t('validate.negativeNumber'),
});

extend('specialNumber', {
  validate: (value) => !REGEX_SPECIAL_CHARACTER.test(value),
  message: i18n.t('validate.specialNumber'),
});

extend('formatNumber', {
  validate: (value) => REGEX_NUMBER.test(value),
  message: i18n.t('validate.formatNumber'),
});

extend('decimalNumber', {
  validate: (value) => !DECIMAL_NUMBER.test(value),
  message: i18n.t('validate.decimalNumber'),
});

extend('formatYear', {
  validate: (value) => value.length === 4,
  message: i18n.t('validate.formatYear'),
});
