<template>
  <el-dialog
    :title="title"
    :visible.sync="openDialog"
    :show-close="false"
    :close-on-click-modal="false"
    class="dialog"
    :class="{ 'none-title': !title }"
    :destroy-on-close="true"
  >
    <slot />
  </el-dialog>
</template>

<script>
export default {
  props: {
    openDialog: Boolean,
    title: String,
  },
};
</script>

<style lang="scss">
@import './styles.module.scss';
</style>
