<template>
  <el-col :sm="12">
    <base-dialog :title="$t('product.addTitle')" :openDialog="openAddDialog">
      <ValidationObserver v-slot="{ handleSubmit }">
        <el-form :model="form" label-position="top" class="add-modal">
          <el-row :gutter="50" class="row-input">
            <el-col :xs="24" :sm="12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <el-form-item
                  :label="$t('product.category')"
                  :error="errors[0]"
                >
                  <v-select
                    @search="searchCategory"
                    @input="selectCategory"
                    @open="openCategory"
                    :filterable="false"
                    v-model="categoryAutoComplete.label"
                    :options="[
                      ...[...categoryAutoComplete.options].map((category) => ({
                        label: category.value,
                        value: category.id,
                      })),
                    ]"
                    ><template #open-indicator="{ attributes }">
                      <img
                        v-bind="attributes"
                        src="@/assets/images/down-icon.png"
                        class="down-icon"
                        :class="form.categoryId && `selected`"
                      />
                    </template>

                    <template #no-options="">
                      <div
                        class="spinner"
                        v-if="categoryAutoComplete.isLoading"
                      >
                        <i class="el-icon-loading"></i>
                      </div>
                      <div v-else>{{ $t('common.emptyText') }}</div>
                    </template></v-select
                  >
                </el-form-item>
              </ValidationProvider>
            </el-col>

            <el-col :xs="24" :sm="12">
              <ValidationProvider
                :rules="form.categoryId && `required`"
                v-slot="{ errors }"
              >
                <el-form-item
                  :label="$t('product.subcategory')"
                  :error="errors[0]"
                >
                  <v-select
                    class="select--data"
                    @search="searchSubcategory"
                    @input="selectSubcategory"
                    @open="openSubcategory"
                    :filterable="false"
                    :disabled="!form.categoryId"
                    v-model="subcategoryAutoComplete.label"
                    :options="[
                      ...[...subcategoryAutoComplete.options].map(
                        (subcategory) => ({
                          label: subcategory.value,
                          value: subcategory.id,
                        })
                      ),
                    ]"
                    ><template #open-indicator="{ attributes }">
                      <img
                        v-bind="attributes"
                        src="@/assets/images/down-icon.png"
                        class="down-icon"
                        :class="form.subcategoryId && `selected`"
                    /></template>

                    <template #no-options="">
                      <div
                        class="spinner"
                        v-if="subcategoryAutoComplete.isLoading"
                      >
                        <i class="el-icon-loading"></i>
                      </div>
                      <div v-else>{{ $t('common.emptyText') }}</div>
                    </template>
                  </v-select>
                </el-form-item>
              </ValidationProvider>
            </el-col>
          </el-row>

          <el-row :gutter="50" class="row-input">
            <el-col :xs="24" :sm="12">
              <el-form-item :label="$t('product.modelNumber')">
                <v-select
                  class="select--data"
                  @search="searchModelNumber"
                  @input="selectModelNumber"
                  @open="openModelNumber"
                  :filterable="false"
                  :disabled="!form.subcategoryId"
                  v-model="form.modelNumber"
                  :clearable="modelNumberSelected"
                  :clearSearchOnBlur="() => false"
                  :options="[
                    ...[...modelNumberAutoComplete.options].map(
                      (modelNumber) => ({
                        ...modelNumber,
                        label: modelNumber.value,
                        yearOfManufacture: modelNumber.yearOfManufacture,
                        marker: modelNumber.marker,
                        name: modelNumber.name,
                      })
                    ),
                  ]"
                  ><template #open-indicator="{ attributes }">
                    <img
                      v-bind="attributes"
                      src="@/assets/images/down-icon.png"
                      class="down-icon"
                      :class="form.modelNumber && `selected`"
                    />
                  </template>
                  <template #no-options="">
                    <div
                      class="spinner"
                      v-if="modelNumberAutoComplete.isLoading"
                    >
                      <i class="el-icon-loading"></i>
                    </div>
                    <div v-else>{{ $t('common.emptyText') }}</div>
                  </template></v-select
                >
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12">
              <ValidationProvider
                :rules="form.subcategoryId && `required|nameDataMax`"
                v-slot="{ errors }"
              >
                <el-form-item
                  :label="$t('product.productName')"
                  :error="errors[0]"
                >
                  <input
                    class="input-native input-cancel"
                    :disabled="!form.subcategoryId"
                    v-model="form.name"
                    autocomplete="off"
                    @blur="isCancelIcon1 = false"
                    @focus="isCancelIcon1 = true"
                  />
                  <img
                    v-if="form.subcategoryId && form.name"
                    :class="{
                      'cancel--icon': true,
                      'display--icon': isCancelIcon1 && form.name,
                      'hide--icon': !isCancelIcon1,
                    }"
                    @click="form.name = null"
                    src="@/assets/images/cancel.png"
                    alt="cancel"
                  />
                </el-form-item>
              </ValidationProvider>
            </el-col>
          </el-row>

          <el-row :gutter="50" class="row-input">
            <el-col :xs="24" :sm="12">
              <ValidationProvider
                :rules="form.subcategoryId && `required`"
                v-slot="{ errors }"
              >
                <el-form-item
                  :label="$t('product.conditionType')"
                  :error="errors[0]"
                >
                  <v-select
                    v-model="form.conditionType"
                    :disabled="!form.subcategoryId"
                    :searchable="false"
                    :options="[
                      ...[...EConditionTypeProduct.getKeys()].map((key) => ({
                        label: key,
                        value: EConditionTypeProduct.getValue(key),
                      })),
                    ]"
                    ><template #open-indicator="{ attributes }">
                      <img
                        v-bind="attributes"
                        src="@/assets/images/down-icon.png"
                        class="down-icon"
                        :class="form.conditionType && `selected`"
                      /> </template
                    ><template #no-options="">
                      {{ $t('common.emptyText') }}
                    </template></v-select
                  >
                </el-form-item>
              </ValidationProvider>
            </el-col>
            <el-col :xs="24" :sm="12">
              <ValidationProvider
                :rules="form.subcategoryId && `nameDataMax`"
                v-slot="{ errors }"
              >
                <el-form-item :label="$t('product.marker')" :error="errors[0]">
                  <input
                    class="input-native input-cancel"
                    :disabled="!form.subcategoryId"
                    v-model="form.marker"
                    autocomplete="off"
                    @blur="isCancelIcon5 = false"
                    @focus="isCancelIcon5 = true"
                  />
                  <img
                    v-if="form.subcategoryId && form.marker"
                    :class="{
                      'cancel--icon': true,
                      'display--icon': isCancelIcon5 && form.marker,
                      'hide--icon': !isCancelIcon5,
                    }"
                    @click="form.marker = null"
                    src="@/assets/images/cancel.png"
                    alt="cancel"
                  />
                </el-form-item>
              </ValidationProvider>
            </el-col>
          </el-row>

          <el-row :gutter="50" class="row-input">
            <el-col :xs="24" :sm="12">
              <ValidationProvider
                :rules="
                  form.subcategoryId &&
                  `formatYear|negativeNumber|decimalNumber|formatNumber`
                "
                v-slot="{ errors }"
              >
                <el-form-item :label="$t('product.years')" :error="errors[0]">
                  <input
                    class="input input-native input-cancel"
                    :disabled="!form.subcategoryId"
                    v-model="form.yearOfManufacture"
                    type="text"
                    style="width: 100%"
                    @blur="handleBlurYear"
                    @focus="isCancelIcon7 = true"
                    placeholder="yyyy"
                    maxlength="4"
                  />
                  <img
                    v-if="form.subcategoryId && form.yearOfManufacture"
                    :class="{
                      'cancel--icon': true,
                      'display--icon': isCancelIcon7 && form.yearOfManufacture,
                      'hide--icon': !isCancelIcon7,
                    }"
                    @click="handleClickCancel(form.yearOfManufacture)"
                    src="@/assets/images/cancel.png"
                    alt="cancel"
                  />
                </el-form-item>
              </ValidationProvider>
            </el-col>
            <el-col :xs="24" :sm="12">
              <ValidationProvider
                :rules="
                  form.subcategoryId &&
                  `required|negativeNumber|decimalNumber|formatNumber`
                "
                v-slot="{ errors }"
              >
                <el-form-item
                  :label="$t('product.purchasePrice')"
                  :error="errors[0]"
                >
                  <input
                    class="input-native border-color input-cancel"
                    :disabled="!form.subcategoryId"
                    v-model="form.buyPrice"
                    autocomplete="off"
                    :maxlength="10"
                    @blur="handleBlurBuyPrice"
                    @focus="isCancelIcon3 = true"
                  />
                  <img
                    v-if="form.subcategoryId && form.buyPrice"
                    :class="{
                      'cancel--icon': true,
                      'display--icon': isCancelIcon3 && form.buyPrice,
                      'hide--icon': !isCancelIcon3,
                    }"
                    @click="form.buyPrice = null"
                    src="@/assets/images/cancel.png"
                    alt="cancel"
                  />
                </el-form-item>
              </ValidationProvider>
            </el-col>
          </el-row>

          <el-row :gutter="50" class="row-input">
            <el-col :xs="24" :sm="12">
              <ValidationProvider
                :rules="
                  form.subcategoryId &&
                  `negativeNumber|decimalNumber|formatNumber`
                "
                v-slot="{ errors }"
              >
                <el-form-item :label="$t('product.price')" :error="errors[0]">
                  <input
                    class="input-native border-color input-cancel"
                    :disabled="!form.subcategoryId"
                    v-model="form.sellPrice"
                    autocomplete="off"
                    :maxlength="10"
                    @blur="handleBurSellPrice"
                    @focus="isCancelIcon4 = true"
                  />
                  <img
                    v-if="form.subcategoryId && form.sellPrice"
                    :class="{
                      'cancel--icon': true,
                      'display--icon': isCancelIcon4 && form.sellPrice,
                      'hide--icon': !isCancelIcon4,
                    }"
                    @click="form.sellPrice = null"
                    src="@/assets/images/cancel.png"
                    alt="cancel"
                  />
                </el-form-item>
              </ValidationProvider>
            </el-col>
            <el-col :xs="24" :sm="12">
              <ValidationProvider
                :rules="form.subcategoryId && `required`"
                v-slot="{ errors }"
              >
                <el-form-item :label="$t('product.store')" :error="errors[0]">
                  <v-select
                    @search="searchStore"
                    @input="selectStore"
                    @open="openStore"
                    :disabled="!form.subcategoryId"
                    v-model="storeAutoComplete.label"
                    :options="[
                      ...[...storeAutoComplete.options].map((store) => ({
                        label: store.value,
                        value: store.id,
                      })),
                    ]"
                    ><template #open-indicator="{ attributes }">
                      <img
                        v-bind="attributes"
                        src="@/assets/images/down-icon.png"
                        class="down-icon"
                        :class="form.storeId && `selected`"
                      /> </template
                    ><template #no-options="">
                      <div class="spinner" v-if="storeAutoComplete.isLoading">
                        <i class="el-icon-loading"></i>
                      </div>
                      <div v-else>{{ $t('common.emptyText') }}</div>
                    </template></v-select
                  >
                </el-form-item>
              </ValidationProvider>
            </el-col>
          </el-row>

          <el-row :gutter="50" class="row-input">
            <el-col :xs="24" :sm="12">
              <ValidationProvider
                :rules="form.subcategoryId && `required`"
                v-slot="{ errors }"
              >
                <el-form-item :label="$t('product.manager')" :error="errors[0]">
                  <v-select
                    @search="searchEmployee"
                    @input="selectEmployee"
                    @open="openEmployee"
                    :disabled="!form.subcategoryId"
                    v-model="employeeAutoComplete.label"
                    :options="[
                      ...[...employeeAutoComplete.options].map((employee) => ({
                        label: employee.value,
                        value: employee.id,
                      })),
                    ]"
                    ><template #open-indicator="{ attributes }">
                      <img
                        v-bind="attributes"
                        src="@/assets/images/down-icon.png"
                        class="down-icon"
                        :class="form.employeeId && `selected`"
                      /> </template
                    ><template #no-options="">
                      <div
                        class="spinner"
                        v-if="employeeAutoComplete.isLoading"
                      >
                        <i class="el-icon-loading"></i>
                      </div>
                      <div v-else>{{ $t('common.emptyText') }}</div>
                    </template></v-select
                  >
                </el-form-item>
              </ValidationProvider>
            </el-col>
            <el-col :xs="24" :sm="12">
              <ValidationProvider
                :rules="form.subcategoryId && `nameDataMax`"
                v-slot="{ errors }"
              >
                <el-form-item :label="$t('product.note')" :error="errors[0]">
                  <input
                    class="input-native input-cancel"
                    :disabled="!form.subcategoryId"
                    v-model="form.note"
                    autocomplete="off"
                    @blur="isCancelIcon6 = false"
                    @focus="isCancelIcon6 = true"
                  />
                  <img
                    v-if="form.subcategoryId && form.note"
                    :class="{
                      'cancel--icon': true,
                      'display--icon': isCancelIcon6 && form.note,
                      'hide--icon': !isCancelIcon6,
                    }"
                    @click="form.note = null"
                    src="@/assets/images/cancel.png"
                    alt="cancel"
                  />
                </el-form-item>
              </ValidationProvider>
            </el-col>
          </el-row>
          <el-form-item>
            <span class="dialog-footer">
              <el-button
                class="btn btn--cancel"
                @click="handleCloseAddDialog()"
              >
                {{ $t('product.cancelBtn') }}
              </el-button>
              <el-button
                class="btn btn--submit"
                type="submit"
                @click="handleSubmit(submitForm)"
                :disabled="addProductLoading"
              >
                {{ $t('product.confirmBtn') }}
              </el-button>
            </span>
          </el-form-item>
        </el-form>
      </ValidationObserver>
    </base-dialog>
  </el-col>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import {
  handleError,
  notifyMessage,
  formatDate,
  convertHalfWidth,
  redirectRouter,
} from '@/helper';
import {
  EConditionTypeProduct,
  EStatusProduct,
  EPlaceTypeProduct,
} from '@/enums/product';
import { LIMIT, PAGE } from '@/constants/common';
import { productService } from '@/services/product.service';
import debounce from 'lodash.debounce';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { REGEX_ZERO } from '@/constants/regex';
import * as pageRouter from '@/constants/pageRouter';

const BaseDialog = () => import('@/components/Dialog');

export default {
  name: 'AddProductDialog',
  props: {
    openAddDialog: Boolean,
  },
  components: { BaseDialog, ValidationProvider, ValidationObserver },
  data() {
    return {
      isCancelIcon1: false,
      isCancelIcon2: false,
      isCancelIcon3: false,
      isCancelIcon4: false,
      isCancelIcon5: false,
      isCancelIcon6: false,
      modelNumberSelected: false,
      isCancelIcon7: false,
      addProductLoading: false,
      EConditionTypeProduct,
      EStatusProduct,
      EPlaceTypeProduct,
      categoryAutoComplete: {
        label: '',
        isLoading: false,
        options: [],
      },
      subcategoryAutoComplete: {
        label: '',
        isLoading: false,
        options: [],
      },
      storeAutoComplete: {
        label: '',
        isLoading: false,
        options: [],
      },
      employeeAutoComplete: {
        label: '',
        isLoading: false,
        options: [],
      },
      modelNumberAutoComplete: {
        label: '',
        isLoading: false,
        options: [],
      },
      form: {
        storeId: '',
        employeeId: '',
        categoryId: '',
        subcategoryId: '',
        name: '',
        modelNumber: '',
        status: '',
        placeType: '',
        conditionType: '',
        sellPrice: '',
        buyPrice: '',
        quantity: '',
        marker: '',
        date: moment(new Date()).format('YYYY/MM/DD'),
        yearOfManufacture: '',
        note: '',
      },
      checkCancel: false,
    };
  },
  computed: {
    ...mapGetters('category', ['category']),
    ...mapGetters('subcategory', ['subcategory']),
    ...mapGetters('store', ['store']),
    ...mapGetters('employee', ['employee']),
    ...mapGetters('product', ['product', 'modelNumber']),
    mapCategoryData() {
      return this.category.data.map((item) => ({
        id: item.id,
        value: item.name,
      }));
    },
    mapSubcategoryData() {
      return this.subcategory.data.map((item) => ({
        id: item.id,
        value: item.name,
      }));
    },
    mapStoreData() {
      return this.store.data.map((item) => ({
        id: item.id,
        value: item.name,
      }));
    },
    mapEmployeeData() {
      return this.employee.data.map((item) => ({
        id: item.id,
        value: item.name,
      }));
    },
    mapModelNumberData() {
      return this.modelNumber.data.map((item) => ({
        ...item,
        value: item.modelNumber,
      }));
    },
  },
  methods: {
    ...mapActions('category', ['getCategoryList']),
    ...mapActions('subcategory', ['getSubcategoryList']),
    ...mapActions('store', ['getStoreList']),
    ...mapActions('employee', ['getEmployeeList']),
    ...mapActions('product', [
      'getProductList',
      'getModelNumber',
      'getProductStatistic',
    ]),
    handleBlurQuantity(e) {
      this.isCancelIcon2 = false;
      if (REGEX_ZERO.test(e.target.value)) {
        e.target.value = e.target.value.replace(REGEX_ZERO, '');
      }
      this.form.quantity = convertHalfWidth(e.target.value.trim());
    },
    handleBlurBuyPrice(e) {
      this.isCancelIcon3 = false;
      this.form.buyPrice = convertHalfWidth(e.target.value.trim());
    },
    handleBurSellPrice(e) {
      this.isCancelIcon4 = false;
      this.form.sellPrice = convertHalfWidth(e.target.value.trim());
    },
    handleBlurYear(e) {
      this.isCancelIcon7 = false;
      this.form.yearOfManufacture = convertHalfWidth(e.target.value.trim());
    },

    async submitForm() {
      this.addProductLoading = true;

      await productService
        .add({
          ...this.form,
          yearOfManufacture:
            Number(this.form.yearOfManufacture) !== 0
              ? Number(this.form.yearOfManufacture)
              : undefined,
          marker: this.form.marker ? this.form.marker : null,
          modelNumber: this.form.modelNumber ? this.form.modelNumber : null,
          note: this.form.note ? this.form.note : null,
          sellPrice: +this.form.sellPrice ? +this.form.sellPrice : null,
          buyPrice: +this.form.buyPrice,
          quantity: 1,
          date: formatDate(this.form.date),
          conditionType: this.form.conditionType.value,
          status: this.form.status.value,
          placeType: this.form.placeType.value,
        })
        .then(() => {
          this.getProductList({
            limit: LIMIT,
            page: PAGE,
          });
          this.getProductStatistic({
            params: {
              page: undefined,
              limit: undefined,
            },
          });

          redirectRouter({
            path: pageRouter.PRODUCT,
            query: {},
          });
          this.$emit('handleCloseAddDialog');
          this.form = {
            storeId: '',
            employeeId: '',
            categoryId: '',
            subcategoryId: '',
            name: '',
            modelNumber: '',
            status: '',
            placeType: '',
            conditionType: '',
            sellPrice: '',
            buyPrice: '',
            quantity: '',
            marker: '',
            date: moment(new Date()).format('YYYY/MM/DD'),
            yearOfManufacture: '',
            note: '',
          };
          this.categoryAutoComplete.label = null;
          this.subcategoryAutoComplete.label = null;
          this.modelNumberAutoComplete.label = null;
          this.storeAutoComplete.label = null;
          this.employeeAutoComplete.label = null;
          notifyMessage(this.$t('common.addSuccess'), 'success');
        })
        .catch((error) => {
          handleError(error);
        })
        .finally(() => {
          this.addProductLoading = false;
        });
    },
    handleCloseAddDialog() {
      this.$emit('handleCloseAddDialog');
      this.form = {};
      this.form.date = moment(new Date()).format('YYYY/MM/DD');

      this.categoryAutoComplete.label = null;
      this.subcategoryAutoComplete.label = null;
      this.modelNumberAutoComplete.label = null;
      this.storeAutoComplete.label = null;
      this.employeeAutoComplete.label = null;
    },

    async openCategory() {
      this.categoryAutoComplete.isLoading = true;

      this.debounceCategory();
    },
    searchCategory(value) {
      this.categoryAutoComplete.isLoading = true;

      this.debounceCategory(value);
    },
    debounceCategory: debounce(async function (value) {
      await this.getCategoryList({
        name: value,
      });

      this.categoryAutoComplete.options = this.mapCategoryData || [];

      this.categoryAutoComplete.isLoading = false;
    }, 350),
    selectCategory(item) {
      if (item) {
        this.form.categoryId = item.value;
        this.form.subcategoryId = null;
        this.subcategoryAutoComplete.label = '';
      } else {
        this.form.categoryId = null;
        this.form.subcategoryId = null;
        this.subcategoryAutoComplete.label = '';
      }

      this.subcategoryAutoComplete.options = [];
      this.modelNumberAutoComplete.options = [];
    },

    openSubcategory() {
      this.subcategoryAutoComplete.isLoading = true;

      this.debounceSubcategory();
    },
    searchSubcategory(value) {
      this.subcategoryAutoComplete.isLoading = true;

      this.debounceSubcategory(value);
    },
    debounceSubcategory: debounce(async function (value) {
      await this.getSubcategoryList({
        categoryId: this.form.categoryId,
        params: {
          name: value,
        },
      });
      this.subcategoryAutoComplete.options = this.mapSubcategoryData || [];

      this.subcategoryAutoComplete.isLoading = false;
    }, 350),
    selectSubcategory(item) {
      if (item) {
        this.form.subcategoryId = item.value;
      } else {
        this.form.subcategoryId = null;
      }

      this.modelNumberAutoComplete.options = [];
    },

    openStore() {
      this.storeAutoComplete.isLoading = true;

      this.debounceStore();
    },
    searchStore(value) {
      this.storeAutoComplete.isLoading = true;

      this.debounceStore(value);
    },
    debounceStore: debounce(async function (value) {
      await this.getStoreList({
        freeText: value,
        onlyName: 1,
      });
      this.storeAutoComplete.options = this.mapStoreData || [];

      this.storeAutoComplete.isLoading = false;
    }, 350),
    selectStore(item) {
      if (item) {
        this.form.storeId = item.value;
      } else {
        this.form.storeId = null;
      }
    },

    openEmployee() {
      this.employeeAutoComplete.isLoading = true;

      this.debounceEmployee();
    },
    searchEmployee(value) {
      this.employeeAutoComplete.isLoading = true;

      this.debounceEmployee(value);
    },
    debounceEmployee: debounce(async function (value) {
      await this.getEmployeeList({
        name: value,
      });
      this.employeeAutoComplete.options = this.mapEmployeeData || [];

      this.employeeAutoComplete.isLoading = false;
    }, 350),
    selectEmployee(item) {
      if (item) {
        this.form.employeeId = item.value;
      } else {
        this.form.employeeId = null;
      }
    },

    openModelNumber() {
      this.modelNumberAutoComplete.isLoading = true;

      this.debounceModelNumber();
    },
    searchModelNumber(value) {
      this.form.modelNumber = value;

      this.modelNumberSelected = false;

      this.modelNumberAutoComplete.isLoading = true;

      this.debounceModelNumber(value);
    },
    debounceModelNumber: debounce(async function (value) {
      await this.getModelNumber({
        modelNumber: value,
        categoryId: this.categoryAutoComplete.label.value,
        subcategoryId: this.subcategoryAutoComplete.label.value,
        isSearch: 0,
      });

      this.modelNumberAutoComplete.options = this.mapModelNumberData || [];

      this.modelNumberAutoComplete.isLoading = false;
    }, 350),
    selectModelNumber(item) {
      if (item) {
        this.form.modelNumber = item.modelNumber;
        this.form.name = item.name;
        this.form.marker = item.marker;
        this.form.yearOfManufacture = item.yearOfManufacture
          ? String(item.yearOfManufacture)
          : undefined;
        this.modelNumberSelected = true;
      } else {
        this.form.modelNumber = null;
      }
    },
    clear() {
      return false;
    },
    handleClickCancel(value) {
      value = null;
      this.checkCancel = true;
      this.form.yearOfManufacture = value;
    },
  },
  watch: {
    checkCancel() {
      this.form.yearOfManufacture = undefined;
    },
  },
};
</script>

<style lang="scss" scope>
.add-modal {
  .el-autocomplete {
    width: 100%;
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    _::-webkit-full-page-media,
    _:future,
    :root,
    .vs__search {
      margin-top: 0px !important;
      padding: 0 !important;
      padding-left: 14px !important;
    }
  }

  .el-autocomplete-suggestion {
    margin: 0 !important;
  }
  .border-color {
    border-color: #f69a2a !important;

    input {
      border-color: #f69a2a !important;
    }
  }
}
</style>
