import { BaseService } from './base.service';

class CategoryService extends BaseService {
  async add(payload) {
    const { data } = await this.axios.post('/api/categories', payload);

    return data;
  }

  async getList(params) {
    const { data } = await this.axios.get('/api/categories', {
      params,
    });
    return data;
  }

  async getDetail(id) {
    const { data } = await this.axios.get(`/api/categories/${id}`);
    return data.data;
  }

  async edit(payload, id) {
    const { data } = await this.axios.put(`/api/categories/${id}`, payload);

    return data;
  }

  async delete(id) {
    const { data } = await this.axios.delete(`/api/categories/${id}`);

    return data;
  }
}

export const categoryService = new CategoryService();
