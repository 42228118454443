import { BaseService } from './base.service';

class ProductService extends BaseService {
  async add(payload) {
    const { data } = await this.axios.post('/api/products', payload);

    return data;
  }

  async getProductList(params) {
    const { data } = await this.axios.get('/api/products', { params });
    return data;
  }

  async getModelNumber(params) {
    const { data } = await this.axios.get('/api/products/model-number', {
      params,
    });
    return data;
  }

  async getProductStatistic(params) {
    const { data } = await this.axios.get('/api/products/statistical', {
      params,
    });

    return data.data;
  }

  async getProductDetail(id) {
    const { data } = await this.axios.get(`/api/products/${id}`);

    return data.data;
  }

  async getHistoryDetail(id) {
    const { data } = await this.axios.get(`/api/products/${id}/soft-delete`);

    return data.data;
  }

  async getProductName(id) {
    const { data } = await this.axios.get(`/api/products/${id}/name`);

    return data.data;
  }

  async getProductHistory(params) {
    const { data } = await this.axios.get('/api/products/action-history', {
      params,
    });

    return data;
  }

  async getProductHistoryDetail(id, params) {
    const { data } = await this.axios.get(
      `/api/products/${id}/action-history`,
      {
        params,
      }
    );

    return data;
  }

  async edit(payload, id) {
    const { data } = await this.axios.put(`/api/products/${id}`, payload);

    return data;
  }

  async delete(id) {
    const { data } = await this.axios.delete(`/api/products/${id}`);

    return data;
  }

  async exportCsv(params) {
    const { data } = await this.axios.get('/api/products/export-csv', {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      params: params,
    });

    return data;
  }
}

export const productService = new ProductService();
