import { productService } from '@/services/product.service';

const initialState = {
  data: {},
  modelNumber: {},
  statisticData: {},
  isLoading: false,
  error: {},
  statisticError: {},
  productDetail: {},
  productHistory: {},
  productHistoryDetail: {},
  productName: {},
};

export const product = {
  namespaced: true,
  state: initialState,
  getters: {
    product: (state) => state.data,
    productDetail: (state) => state.productDetail,
    productHistory: (state) => state.productHistory,
    productName: (state) => state.productName,
    productHistoryDetail: (state) => state.productHistoryDetail,
    modelNumber: (state) => state.modelNumber,
    statisticData: (state) => state.statisticData,
    isLoadingProduct: (state) => state.isLoading,
    error: (state) => state.error,
    statisticError: (state) => state.statisticError,
  },
  mutations: {
    loading(state) {
      state.isLoading = true;
    },
    loadingSuccess(state) {
      state.isLoading = false;
    },
    setData(state, data) {
      state.data = data;
    },
    setModelNumber(state, data) {
      state.modelNumber = data;
    },
    setStatisticData(state, data) {
      state.statisticData = data;
    },
    setError(state, error) {
      state.error = error;
    },
    setStatisticError(state, error) {
      state.statisticError = error;
    },
    setProductDetail(state, data) {
      state.productDetail = data;
    },
    setProductHistory(state, data) {
      state.productHistory = data;
    },
    setProductHistoryDetail(state, data) {
      state.productHistoryDetail = data;
    },
    setProductName(state, data) {
      state.productName = data;
    },
  },
  actions: {
    async getProductList({ commit }, params) {
      commit('loading');
      try {
        const response = await productService.getProductList(params);
        commit('setData', response);
      } catch (error) {
        commit('setError', error);
      } finally {
        commit('loadingSuccess');
      }
    },

    async getProductDetail({ commit }, { id }) {
      commit('loading');
      try {
        const response = await productService.getProductDetail(id);
        commit('setProductDetail', response);
      } catch (error) {
        commit('setError', error);
      } finally {
        commit('loadingSuccess');
      }
    },

    async getHistoryDetail({ commit }, { id }) {
      commit('loading');
      try {
        const response = await productService.getHistoryDetail(id);
        commit('setProductDetail', response);
      } catch (error) {
        commit('setError', error);
      } finally {
        commit('loadingSuccess');
      }
    },

    async getProductName({ commit }, { id }) {
      commit('loading');
      try {
        const response = await productService.getProductName(id);
        commit('setProductName', response);
      } catch (error) {
        commit('setError', error);
      } finally {
        commit('loadingSuccess');
      }
    },

    async getProductStatistic({ commit }, { params }) {
      commit('loading');
      try {
        const response = await productService.getProductStatistic(params);
        commit('setStatisticData', response);
      } catch (error) {
        commit('setStatisticError', error);
      } finally {
        commit('loadingSuccess');
      }
    },

    async getProductHistory({ commit }, params) {
      commit('loading');
      try {
        const response = await productService.getProductHistory(params);
        commit('setProductHistory', response);
      } catch (error) {
        commit('setStatisticError', error);
      } finally {
        commit('loadingSuccess');
      }
    },

    async getProductHistoryDetail({ commit }, { id, params }) {
      commit('loading');
      try {
        const response = await productService.getProductHistoryDetail(
          id,
          params
        );
        commit('setProductHistoryDetail', response);
      } catch (error) {
        commit('setStatisticError', error);
      } finally {
        commit('loadingSuccess');
      }
    },

    async getModelNumber({ commit }, params) {
      commit('loading');
      try {
        const response = await productService.getModelNumber(params);
        commit('setModelNumber', response);
        return response;
      } catch (error) {
        commit('setError', error);
      } finally {
        commit('loadingSuccess');
      }
    },

    async exportCsv({ commit }, options) {
      try {
        const data = await productService.exportCsv(options);

        return data;
      } catch (error) {
        commit('setError', error);
      }
    },
  },
};
