<template>
  <component :is="this.$route.meta.layout || 'div'" class="wrap-content">
    <router-view />
  </component>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'App',
  methods: {
    ...mapActions('user', ['getInfoUser']),
  },
  created() {
    this.getInfoUser();
  },
};
</script>
