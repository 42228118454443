import { BaseService } from './base.service';

class StoreService extends BaseService {
  async add(payload) {
    const { data } = await this.axios.post('/api/stores', payload);

    return data;
  }

  async getStoreList(params) {
    const { data } = await this.axios.get('/api/stores', { params });
    return data;
  }

  async getDetail(id) {
    const { data } = await this.axios.get(`/api/stores/${id}`);

    return data.data;
  }

  async edit(payload, id) {
    const { data } = await this.axios.put(`/api/stores/${id}`, payload);

    return data;
  }

  async delete(id) {
    const { data } = await this.axios.delete(`/api/stores/${id}`);

    return data;
  }
}

export const storeService = new StoreService();
