import { categoryService } from '@/services/category.service';

const initialState = {
  data: {},
  detailData: {},
  isLoading: false,
  error: {},
};

export const category = {
  namespaced: true,
  state: initialState,
  getters: {
    category: (state) => state.data,
    categoryDetail: (state) => state.detailData,
    isLoadingCategory: (state) => state.isLoading,
    error: (state) => state.error,
  },
  mutations: {
    loading(state) {
      state.isLoading = true;
    },
    loadingSuccess(state) {
      state.isLoading = false;
    },
    setData(state, data) {
      state.data = data;
    },
    setError(state, error) {
      state.error = error;
    },
    setDetailData(state, data) {
      state.detailData = data;
    },
  },
  actions: {
    async getCategoryList({ commit }, params) {
      commit('loading');
      try {
        const response = await categoryService.getList(params);
        commit('setData', response);
        return response;
      } catch (error) {
        commit('setError', error);
      } finally {
        commit('loadingSuccess');
      }
    },

    async getCategoryDetail({ commit }, { categoryId }) {
      commit('loading');
      try {
        const response = await categoryService.getDetail(categoryId);
        commit('setDetailData', response);
      } catch (error) {
        commit('setError', error);
      } finally {
        commit('loadingSuccess');
      }
    },
  },
};
