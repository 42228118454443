var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-col',{attrs:{"sm":12}},[_c('base-dialog',{attrs:{"title":_vm.$t('product.addTitle'),"openDialog":_vm.openAddDialog}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('el-form',{staticClass:"add-modal",attrs:{"model":_vm.form,"label-position":"top"}},[_c('el-row',{staticClass:"row-input",attrs:{"gutter":50}},[_c('el-col',{attrs:{"xs":24,"sm":12}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$t('product.category'),"error":errors[0]}},[_c('v-select',{attrs:{"filterable":false,"options":[].concat( [].concat( _vm.categoryAutoComplete.options ).map(function (category) { return ({
                      label: category.value,
                      value: category.id,
                    }); }) )},on:{"search":_vm.searchCategory,"input":_vm.selectCategory,"open":_vm.openCategory},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
                  var attributes = ref.attributes;
return [_c('img',_vm._b({staticClass:"down-icon",class:_vm.form.categoryId && "selected",attrs:{"src":require("@/assets/images/down-icon.png")}},'img',attributes,false))]}},{key:"no-options",fn:function(){return [(_vm.categoryAutoComplete.isLoading)?_c('div',{staticClass:"spinner"},[_c('i',{staticClass:"el-icon-loading"})]):_c('div',[_vm._v(_vm._s(_vm.$t('common.emptyText')))])]},proxy:true}],null,true),model:{value:(_vm.categoryAutoComplete.label),callback:function ($$v) {_vm.$set(_vm.categoryAutoComplete, "label", $$v)},expression:"categoryAutoComplete.label"}})],1)]}}],null,true)})],1),_c('el-col',{attrs:{"xs":24,"sm":12}},[_c('ValidationProvider',{attrs:{"rules":_vm.form.categoryId && "required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$t('product.subcategory'),"error":errors[0]}},[_c('v-select',{staticClass:"select--data",attrs:{"filterable":false,"disabled":!_vm.form.categoryId,"options":[].concat( [].concat( _vm.subcategoryAutoComplete.options ).map(
                      function (subcategory) { return ({
                        label: subcategory.value,
                        value: subcategory.id,
                      }); }
                    ) )},on:{"search":_vm.searchSubcategory,"input":_vm.selectSubcategory,"open":_vm.openSubcategory},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
                  var attributes = ref.attributes;
return [_c('img',_vm._b({staticClass:"down-icon",class:_vm.form.subcategoryId && "selected",attrs:{"src":require("@/assets/images/down-icon.png")}},'img',attributes,false))]}},{key:"no-options",fn:function(){return [(_vm.subcategoryAutoComplete.isLoading)?_c('div',{staticClass:"spinner"},[_c('i',{staticClass:"el-icon-loading"})]):_c('div',[_vm._v(_vm._s(_vm.$t('common.emptyText')))])]},proxy:true}],null,true),model:{value:(_vm.subcategoryAutoComplete.label),callback:function ($$v) {_vm.$set(_vm.subcategoryAutoComplete, "label", $$v)},expression:"subcategoryAutoComplete.label"}})],1)]}}],null,true)})],1)],1),_c('el-row',{staticClass:"row-input",attrs:{"gutter":50}},[_c('el-col',{attrs:{"xs":24,"sm":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('product.modelNumber')}},[_c('v-select',{staticClass:"select--data",attrs:{"filterable":false,"disabled":!_vm.form.subcategoryId,"clearable":_vm.modelNumberSelected,"clearSearchOnBlur":function () { return false; },"options":[].concat( [].concat( _vm.modelNumberAutoComplete.options ).map(
                    function (modelNumber) { return (Object.assign({}, modelNumber,
                      {label: modelNumber.value,
                      yearOfManufacture: modelNumber.yearOfManufacture,
                      marker: modelNumber.marker,
                      name: modelNumber.name})); }
                  ) )},on:{"search":_vm.searchModelNumber,"input":_vm.selectModelNumber,"open":_vm.openModelNumber},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
                var attributes = ref.attributes;
return [_c('img',_vm._b({staticClass:"down-icon",class:_vm.form.modelNumber && "selected",attrs:{"src":require("@/assets/images/down-icon.png")}},'img',attributes,false))]}},{key:"no-options",fn:function(){return [(_vm.modelNumberAutoComplete.isLoading)?_c('div',{staticClass:"spinner"},[_c('i',{staticClass:"el-icon-loading"})]):_c('div',[_vm._v(_vm._s(_vm.$t('common.emptyText')))])]},proxy:true}],null,true),model:{value:(_vm.form.modelNumber),callback:function ($$v) {_vm.$set(_vm.form, "modelNumber", $$v)},expression:"form.modelNumber"}})],1)],1),_c('el-col',{attrs:{"xs":24,"sm":12}},[_c('ValidationProvider',{attrs:{"rules":_vm.form.subcategoryId && "required|nameDataMax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$t('product.productName'),"error":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"input-native input-cancel",attrs:{"disabled":!_vm.form.subcategoryId,"autocomplete":"off"},domProps:{"value":(_vm.form.name)},on:{"blur":function($event){_vm.isCancelIcon1 = false},"focus":function($event){_vm.isCancelIcon1 = true},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),(_vm.form.subcategoryId && _vm.form.name)?_c('img',{class:{
                    'cancel--icon': true,
                    'display--icon': _vm.isCancelIcon1 && _vm.form.name,
                    'hide--icon': !_vm.isCancelIcon1,
                  },attrs:{"src":require("@/assets/images/cancel.png"),"alt":"cancel"},on:{"click":function($event){_vm.form.name = null}}}):_vm._e()])]}}],null,true)})],1)],1),_c('el-row',{staticClass:"row-input",attrs:{"gutter":50}},[_c('el-col',{attrs:{"xs":24,"sm":12}},[_c('ValidationProvider',{attrs:{"rules":_vm.form.subcategoryId && "required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$t('product.conditionType'),"error":errors[0]}},[_c('v-select',{attrs:{"disabled":!_vm.form.subcategoryId,"searchable":false,"options":[].concat( [].concat( _vm.EConditionTypeProduct.getKeys() ).map(function (key) { return ({
                      label: key,
                      value: _vm.EConditionTypeProduct.getValue(key),
                    }); }) )},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
                  var attributes = ref.attributes;
return [_c('img',_vm._b({staticClass:"down-icon",class:_vm.form.conditionType && "selected",attrs:{"src":require("@/assets/images/down-icon.png")}},'img',attributes,false))]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('common.emptyText'))+" ")]},proxy:true}],null,true),model:{value:(_vm.form.conditionType),callback:function ($$v) {_vm.$set(_vm.form, "conditionType", $$v)},expression:"form.conditionType"}})],1)]}}],null,true)})],1),_c('el-col',{attrs:{"xs":24,"sm":12}},[_c('ValidationProvider',{attrs:{"rules":_vm.form.subcategoryId && "nameDataMax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$t('product.marker'),"error":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.marker),expression:"form.marker"}],staticClass:"input-native input-cancel",attrs:{"disabled":!_vm.form.subcategoryId,"autocomplete":"off"},domProps:{"value":(_vm.form.marker)},on:{"blur":function($event){_vm.isCancelIcon5 = false},"focus":function($event){_vm.isCancelIcon5 = true},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "marker", $event.target.value)}}}),(_vm.form.subcategoryId && _vm.form.marker)?_c('img',{class:{
                    'cancel--icon': true,
                    'display--icon': _vm.isCancelIcon5 && _vm.form.marker,
                    'hide--icon': !_vm.isCancelIcon5,
                  },attrs:{"src":require("@/assets/images/cancel.png"),"alt":"cancel"},on:{"click":function($event){_vm.form.marker = null}}}):_vm._e()])]}}],null,true)})],1)],1),_c('el-row',{staticClass:"row-input",attrs:{"gutter":50}},[_c('el-col',{attrs:{"xs":24,"sm":12}},[_c('ValidationProvider',{attrs:{"rules":_vm.form.subcategoryId &&
                "formatYear|negativeNumber|decimalNumber|formatNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$t('product.years'),"error":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.yearOfManufacture),expression:"form.yearOfManufacture"}],staticClass:"input input-native input-cancel",staticStyle:{"width":"100%"},attrs:{"disabled":!_vm.form.subcategoryId,"type":"text","placeholder":"yyyy","maxlength":"4"},domProps:{"value":(_vm.form.yearOfManufacture)},on:{"blur":_vm.handleBlurYear,"focus":function($event){_vm.isCancelIcon7 = true},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "yearOfManufacture", $event.target.value)}}}),(_vm.form.subcategoryId && _vm.form.yearOfManufacture)?_c('img',{class:{
                    'cancel--icon': true,
                    'display--icon': _vm.isCancelIcon7 && _vm.form.yearOfManufacture,
                    'hide--icon': !_vm.isCancelIcon7,
                  },attrs:{"src":require("@/assets/images/cancel.png"),"alt":"cancel"},on:{"click":function($event){return _vm.handleClickCancel(_vm.form.yearOfManufacture)}}}):_vm._e()])]}}],null,true)})],1),_c('el-col',{attrs:{"xs":24,"sm":12}},[_c('ValidationProvider',{attrs:{"rules":_vm.form.subcategoryId &&
                "required|negativeNumber|decimalNumber|formatNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$t('product.purchasePrice'),"error":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.buyPrice),expression:"form.buyPrice"}],staticClass:"input-native border-color input-cancel",attrs:{"disabled":!_vm.form.subcategoryId,"autocomplete":"off","maxlength":10},domProps:{"value":(_vm.form.buyPrice)},on:{"blur":_vm.handleBlurBuyPrice,"focus":function($event){_vm.isCancelIcon3 = true},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "buyPrice", $event.target.value)}}}),(_vm.form.subcategoryId && _vm.form.buyPrice)?_c('img',{class:{
                    'cancel--icon': true,
                    'display--icon': _vm.isCancelIcon3 && _vm.form.buyPrice,
                    'hide--icon': !_vm.isCancelIcon3,
                  },attrs:{"src":require("@/assets/images/cancel.png"),"alt":"cancel"},on:{"click":function($event){_vm.form.buyPrice = null}}}):_vm._e()])]}}],null,true)})],1)],1),_c('el-row',{staticClass:"row-input",attrs:{"gutter":50}},[_c('el-col',{attrs:{"xs":24,"sm":12}},[_c('ValidationProvider',{attrs:{"rules":_vm.form.subcategoryId &&
                "negativeNumber|decimalNumber|formatNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$t('product.price'),"error":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sellPrice),expression:"form.sellPrice"}],staticClass:"input-native border-color input-cancel",attrs:{"disabled":!_vm.form.subcategoryId,"autocomplete":"off","maxlength":10},domProps:{"value":(_vm.form.sellPrice)},on:{"blur":_vm.handleBurSellPrice,"focus":function($event){_vm.isCancelIcon4 = true},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "sellPrice", $event.target.value)}}}),(_vm.form.subcategoryId && _vm.form.sellPrice)?_c('img',{class:{
                    'cancel--icon': true,
                    'display--icon': _vm.isCancelIcon4 && _vm.form.sellPrice,
                    'hide--icon': !_vm.isCancelIcon4,
                  },attrs:{"src":require("@/assets/images/cancel.png"),"alt":"cancel"},on:{"click":function($event){_vm.form.sellPrice = null}}}):_vm._e()])]}}],null,true)})],1),_c('el-col',{attrs:{"xs":24,"sm":12}},[_c('ValidationProvider',{attrs:{"rules":_vm.form.subcategoryId && "required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$t('product.store'),"error":errors[0]}},[_c('v-select',{attrs:{"disabled":!_vm.form.subcategoryId,"options":[].concat( [].concat( _vm.storeAutoComplete.options ).map(function (store) { return ({
                      label: store.value,
                      value: store.id,
                    }); }) )},on:{"search":_vm.searchStore,"input":_vm.selectStore,"open":_vm.openStore},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
                  var attributes = ref.attributes;
return [_c('img',_vm._b({staticClass:"down-icon",class:_vm.form.storeId && "selected",attrs:{"src":require("@/assets/images/down-icon.png")}},'img',attributes,false))]}},{key:"no-options",fn:function(){return [(_vm.storeAutoComplete.isLoading)?_c('div',{staticClass:"spinner"},[_c('i',{staticClass:"el-icon-loading"})]):_c('div',[_vm._v(_vm._s(_vm.$t('common.emptyText')))])]},proxy:true}],null,true),model:{value:(_vm.storeAutoComplete.label),callback:function ($$v) {_vm.$set(_vm.storeAutoComplete, "label", $$v)},expression:"storeAutoComplete.label"}})],1)]}}],null,true)})],1)],1),_c('el-row',{staticClass:"row-input",attrs:{"gutter":50}},[_c('el-col',{attrs:{"xs":24,"sm":12}},[_c('ValidationProvider',{attrs:{"rules":_vm.form.subcategoryId && "required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$t('product.manager'),"error":errors[0]}},[_c('v-select',{attrs:{"disabled":!_vm.form.subcategoryId,"options":[].concat( [].concat( _vm.employeeAutoComplete.options ).map(function (employee) { return ({
                      label: employee.value,
                      value: employee.id,
                    }); }) )},on:{"search":_vm.searchEmployee,"input":_vm.selectEmployee,"open":_vm.openEmployee},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
                  var attributes = ref.attributes;
return [_c('img',_vm._b({staticClass:"down-icon",class:_vm.form.employeeId && "selected",attrs:{"src":require("@/assets/images/down-icon.png")}},'img',attributes,false))]}},{key:"no-options",fn:function(){return [(_vm.employeeAutoComplete.isLoading)?_c('div',{staticClass:"spinner"},[_c('i',{staticClass:"el-icon-loading"})]):_c('div',[_vm._v(_vm._s(_vm.$t('common.emptyText')))])]},proxy:true}],null,true),model:{value:(_vm.employeeAutoComplete.label),callback:function ($$v) {_vm.$set(_vm.employeeAutoComplete, "label", $$v)},expression:"employeeAutoComplete.label"}})],1)]}}],null,true)})],1),_c('el-col',{attrs:{"xs":24,"sm":12}},[_c('ValidationProvider',{attrs:{"rules":_vm.form.subcategoryId && "nameDataMax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$t('product.note'),"error":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.note),expression:"form.note"}],staticClass:"input-native input-cancel",attrs:{"disabled":!_vm.form.subcategoryId,"autocomplete":"off"},domProps:{"value":(_vm.form.note)},on:{"blur":function($event){_vm.isCancelIcon6 = false},"focus":function($event){_vm.isCancelIcon6 = true},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "note", $event.target.value)}}}),(_vm.form.subcategoryId && _vm.form.note)?_c('img',{class:{
                    'cancel--icon': true,
                    'display--icon': _vm.isCancelIcon6 && _vm.form.note,
                    'hide--icon': !_vm.isCancelIcon6,
                  },attrs:{"src":require("@/assets/images/cancel.png"),"alt":"cancel"},on:{"click":function($event){_vm.form.note = null}}}):_vm._e()])]}}],null,true)})],1)],1),_c('el-form-item',[_c('span',{staticClass:"dialog-footer"},[_c('el-button',{staticClass:"btn btn--cancel",on:{"click":function($event){return _vm.handleCloseAddDialog()}}},[_vm._v(" "+_vm._s(_vm.$t('product.cancelBtn'))+" ")]),_c('el-button',{staticClass:"btn btn--submit",attrs:{"type":"submit","disabled":_vm.addProductLoading},on:{"click":function($event){return handleSubmit(_vm.submitForm)}}},[_vm._v(" "+_vm._s(_vm.$t('product.confirmBtn'))+" ")])],1)])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }