import Vue from 'vue';
import Vuex from 'vuex';

import { auth } from './auth';
import { storeX } from './store';
import { employee } from './employee';
import { category } from './category';
import { subcategory } from './subcategory';
import { product } from './product';
import { user } from './user';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    auth,
    store: storeX,
    employee,
    category,
    subcategory,
    product,
    user,
  },
});
