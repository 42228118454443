<template>
  <th
    scope="col"
    class="table-cell ptb-12"
    :style="`min-width: ${minWidth}px; width: ${width}px`"
    :class="fixed ? `fixed-${fixed}-column` : ''"
  >
    <div class="column-cell text-align-left">{{ title }}</div>
  </th>
</template>

<script>
export default {
  name: 'TableHeader',
  props: {
    title: {
      required: true,
      type: String,
    },
    minWidth: {
      require: false,
      type: Number,
    },
    width: {
      require: false,
      type: Number,
    },
    fixed: {
      type: String,
      require: false,
    },
  },
};
</script>
