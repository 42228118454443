import Enum from './Enum';
import i18n from '@/plugins/i18n';

export const ERolAdmin = new Enum({
  ADMIN: 1,
  USER: 2,
});

export const ESearchHistory = new Enum({
  [i18n.t('enum.login')]: 1,
  [i18n.t('enum.logout')]: 2,
  [i18n.t('enum.add')]: 3,
  [i18n.t('enum.edit')]: 4,
  [i18n.t('enum.delete')]: 5,
});

export const EOperationStatus = new Enum({
  [i18n.t('enum.add')]: 3,
  [i18n.t('enum.edit')]: 4,
  [i18n.t('enum.delete')]: 5,
});
