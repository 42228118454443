import { BaseService } from './base.service';

class EmployeeService extends BaseService {
  async add(payload) {
    const { data } = await this.axios.post('/api/employees', payload);

    return data;
  }

  async getEmployeeList(params) {
    const { data } = await this.axios.get('/api/employees', { params });
    return data;
  }

  async getEmployeeDetail(id) {
    const { data } = await this.axios.get(`/api/employees/${id}`);

    return data.data;
  }

  async edit(payload, id) {
    const { data } = await this.axios.put(`/api/employees/${id}`, payload);

    return data;
  }

  async delete(id) {
    const { data } = await this.axios.delete(`/api/employees/${id}`);

    return data;
  }
}

export const employeeService = new EmployeeService();
