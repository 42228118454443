import { userService } from '@/services/user.service';

const initialState = {
  data: {},
  isLoading: false,
  error: {},
  userInfo: {},
  userHistory: {},
};

export const user = {
  namespaced: true,
  state: initialState,
  getters: {
    user: (state) => state.data,
    userInfo: (state) => state.userInfo,
    userHistory: (state) => state.userHistory,
    isLoadingUser: (state) => state.isLoading,
    error: (state) => state.error,
  },
  mutations: {
    loading(state) {
      state.isLoading = true;
    },
    loadingSuccess(state) {
      state.isLoading = false;
    },
    setData(state, data) {
      state.data = data;
    },
    setUserInfo(state, data) {
      state.userInfo = data;
    },
    setUserInfoIsNull(state) {
      state.userInfo = {};
    },
    setUserHistory(state, data) {
      state.userHistory = data;
    },
    setError(state, error) {
      state.error = error;
    },
  },
  actions: {
    async getUserList({ commit }, params) {
      commit('loading');
      try {
        const response = await userService.getUserList(params);
        commit('setData', response);
      } catch (error) {
        commit('setError', error);
      } finally {
        commit('loadingSuccess');
      }
    },

    async getInfoUser({ commit }) {
      commit('loading');
      try {
        const response = await userService.getInfoUser();
        commit('setUserInfo', response);
      } catch (error) {
        commit('setError', error);
      } finally {
        commit('loadingSuccess');
      }
    },

    async getUserDetail({ commit }, { id }) {
      commit('loading');
      try {
        const response = await userService.getUserDetail(id);
        commit('setData', response);
      } catch (error) {
        commit('setError', error);
      } finally {
        commit('loadingSuccess');
      }
    },

    async getUserHistory({ commit }, { id, params }) {
      commit('loading');
      try {
        const response = await userService.getUserHistory(id, params);
        commit('setUserHistory', response);
      } catch (error) {
        commit('setError', error);
      } finally {
        commit('loadingSuccess');
      }
    },
  },
};
