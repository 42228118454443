<template>
  <div class="header">
    <div class="logo-wrap">
      <div v-on:click="toggleMenu()">
        <img :src="openIcon" class="icon-open-menu" v-if="!isOpen" />
        <img v-else :src="closeIcon" class="icon-colse-menu" />
      </div>

      <div class="logo">
        <div class="logo-avatar">
          <img src="@/assets/images/logo-page.png" alt="logo-page" />
        </div>
        <p>SIM</p>
      </div>
    </div>

    <el-dropdown>
      <span class="el-dropdown-link">
        <div class="avatar-wrap">
          <img
            class="icon-avatar"
            src="../../assets/images/avatar-page.png"
            alt="avatar"
          />
          <i class="el-icon-caret-bottom dropdown-icon" />
        </div>
      </span>

      <el-dropdown-menu slot="dropdown" class="login-menu">
        <el-dropdown-item v-if="userInfo.type === ERolAdmin.ADMIN">
          <router-link :to="pageRouter.CHANGE_PASSWORD">
            {{ $t('screenChangePassword.changePassword') }}
          </router-link>
        </el-dropdown-item>

        <el-dropdown-item class="logout">
          <p @click="handleLogout()">{{ $t('logout.title') }}</p>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { notifyMessage, redirectRouter } from '@/helper';
import closeIcon from '@/assets/images/close_menu.svg';
import openIcon from '@/assets/images/menu.svg';
import { ERolAdmin } from '@/enums/user';

import * as pageRouter from '@/constants/pageRouter';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  data() {
    return {
      ERolAdmin,
      closeIcon,
      openIcon,
      pageRouter,
    };
  },
  props: {
    isOpen: Boolean,
  },
  computed: {
    ...mapGetters('user', ['userInfo']),
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapMutations('user', ['setUserInfoIsNull']),
    toggleMenu() {
      this.$emit('toggleMenu');
    },
    async handleLogout() {
      await this.logout();
      notifyMessage(this.$t('logout.logoutSuccess'), 'success');
      redirectRouter(pageRouter.LOGIN);
      this.setUserInfoIsNull();
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.module.scss';

.el-dropdown-menu__item {
  a,
  p {
    color: #0a98ff !important;
    font-size: 14px;
    margin-top: 0;
  }
}

.login-menu {
  border: 0px;
  outline: 0px;
  width: 138px !important;
  top: 55px !important;
  right: 15px !important;
  left: unset !important;
}
</style>
