import Vue from 'vue';

import router from '@/router';
import { store } from '@/store';
import i18n from '@/plugins/i18n';
import element from '@/plugins/element-ui';
import vSelect from 'vue-select';
import '@/plugins/veeValidate';
import '@/helper/vee-validate';

import 'vue-select/src/scss/vue-select.scss';

import '@/styles/index.scss';

import App from '@/App.vue';
import Layout from '@/components/Layout';
import { LAYOUT } from '@/constants/pageName';
import TableComponent from '@/components/BaseTable/TableComponent';
import TableCell from '@/components/BaseTable/TableCell';
import TableHeader from '@/components/BaseTable/TableHeader';

import Deselect from '@/components/Deselect';

vSelect.props.components.default = () => ({
  Deselect,
});

Vue.component(LAYOUT, Layout);
Vue.component('table-component', TableComponent);
Vue.component('table-header', TableHeader);
Vue.component('table-cell', TableCell);
Vue.component('v-select', vSelect);

Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f);
      }
    };
    window.addEventListener('scroll', f);
  },
});

Vue.config.productionTip = false;
Promise.all([store.dispatch('user/getInfoUser')]).finally(() => {
  new Vue({
    router,
    store,
    i18n,
    element,
    render: function (h) {
      return h(App);
    },
  }).$mount('#app');
});
