import Vue from 'vue';
import VueRouter from 'vue-router';

import { getToken } from '@/utils/storage.util';
import * as pageName from '@/constants/pageName';
import * as pageRouter from '@/constants/pageRouter';
import { ERolAdmin } from '@/enums/user';
import { store } from '@/store';

const LoginPage = () => import('@/views/Login');
const ChangePassword = () => import('@/views/ChangePassword');

const Employee = () => import('@/views/Employee');
const DetailEmployee = () => import('@/views/Employee/DetailEmployee');

const Store = () => import('@/views/Store');
const StoreDetail = () => import('@/views/Store/DetailStore');

const Product = () => import('@/views/Product');

const Category = () => import('@/views/MasterData/Category');
const Subcategory = () => import('@/views/MasterData/Subcategory');

const User = () => import('@/views/User');
const HistoryDetail = () => import('@/views/User/HistoryDetail');
const HistoryProduct = () => import('@/views/ProductHistory');
const HistoryProductDetail = () => import('@/views/ProductHistory/Detail');

Vue.use(VueRouter);

const routes = [
  {
    path: pageRouter.LOGIN,
    component: LoginPage,
    name: pageName.LOGIN,
  },
  {
    path: pageRouter.CHANGE_PASSWORD,
    component: ChangePassword,
    name: pageName.CHANGE_PASSWORD,
    meta: {
      permissions: ERolAdmin.ADMIN,
    },
  },
  {
    path: pageRouter.HOME,
    redirect: {
      name: pageName.EMPLOYEE,
    },
  },
  {
    path: pageRouter.EMPLOYEE,
    component: Employee,
    name: pageName.EMPLOYEE,
    meta: {
      layout: pageName.LAYOUT,
    },
  },
  {
    path: pageRouter.EMPLOYEE_DETAIL,
    component: DetailEmployee,
    name: pageName.EMPLOYEE_DETAIL,
    meta: {
      layout: pageName.LAYOUT,
    },
  },
  {
    path: pageRouter.STORE,
    component: Store,
    name: pageName.STORE,
    meta: {
      layout: pageName.LAYOUT,
    },
  },
  {
    path: pageRouter.STORE_DETAIL,
    component: StoreDetail,
    name: pageName.STORE_DETAIL,
    meta: {
      layout: pageName.LAYOUT,
    },
  },
  {
    path: pageRouter.PRODUCT,
    component: Product,
    name: pageName.PRODUCT,
    meta: {
      layout: pageName.LAYOUT,
    },
  },
  {
    path: pageRouter.CATEGORY,
    component: Category,
    name: pageName.CATEGORY,
    meta: {
      layout: pageName.LAYOUT,
    },
  },
  {
    path: pageRouter.SUB_CATEGORY,
    component: Subcategory,
    name: pageName.SUB_CATEGORY,
    meta: {
      layout: pageName.LAYOUT,
    },
  },
  {
    path: pageRouter.USER,
    component: User,
    name: pageName.USER,
    meta: {
      layout: pageName.LAYOUT,
      permissions: ERolAdmin.ADMIN,
    },
  },
  {
    path: pageRouter.USER_HISTORY_DETAIL,
    component: HistoryDetail,
    name: pageName.USER_HISTORY_DETAIL,
    meta: {
      layout: pageName.LAYOUT,
      permissions: ERolAdmin.ADMIN,
    },
  },
  {
    path: pageRouter.PRODUCT_HISTORY,
    component: HistoryProduct,
    name: pageName.PRODUCT_HISTORY,
    meta: {
      layout: pageName.LAYOUT,
      permissions: ERolAdmin.ADMIN,
    },
  },
  {
    path: pageRouter.PRODUCT_HISTORY_DETAIL,
    component: HistoryProductDetail,
    name: pageName.PRODUCT_HISTORY_DETAIL,
    meta: {
      layout: pageName.LAYOUT,
      permissions: ERolAdmin.ADMIN,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  const userInfo = store.getters['user/userInfo'];

  if (to.name !== pageName.LOGIN && !getToken()) {
    next({
      name: pageName.LOGIN,
    });
  } else if (
    !userInfo ||
    (to.meta.permissions && to.meta.permissions !== userInfo.type)
  ) {
    next({
      name: pageName.EMPLOYEE,
    });
  } else if (to.name === pageName.LOGIN && getToken()) {
    next({
      name: pageName.EMPLOYEE,
    });
  } else next();
});

export default router;
