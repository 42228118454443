import { employeeService } from '@/services/employee.service';

const initialState = {
  data: {},
  isLoading: false,
  error: {},
};

export const employee = {
  namespaced: true,
  state: initialState,
  getters: {
    employee: (state) => state.data,
    isLoadingEmployee: (state) => state.isLoading,
    error: (state) => state.error,
  },
  mutations: {
    loading(state) {
      state.isLoading = true;
    },
    loadingSuccess(state) {
      state.isLoading = false;
    },
    setData(state, data) {
      state.data = data;
    },
    setError(state, error) {
      state.error = error;
    },
  },
  actions: {
    async getEmployeeList({ commit }, params) {
      commit('loading');
      try {
        const response = await employeeService.getEmployeeList(params);
        commit('setData', response);
      } catch (error) {
        commit('setError', error);
      } finally {
        commit('loadingSuccess');
      }
    },

    async getEmployeeDetail({ commit }, { id }) {
      commit('loading');
      try {
        const response = await employeeService.getEmployeeDetail(id);
        commit('setData', response);
      } catch (error) {
        commit('setError', error);
      } finally {
        commit('loadingSuccess');
      }
    },
  },
};
