<template>
  <el-col :sm="12" class="add-master-data">
    <base-dialog :title="$t('user.detail')" :openDialog="openMasterData">
      <el-form v-if="categoryObj" label-position="top">
        <el-row :gutter="50" class="row-input">
          <el-col :xs="24">
            <el-form-item :label="$t('category.name')" prop="name">
              <el-input
                class="input"
                v-model="categoryObj.name"
                autocomplete="off"
                :readonly="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <span class="dialog-footer">
            <el-button class="btn btn--cancel" @click="handleCloseMasterData()">
              OK
            </el-button>
          </span>
        </el-form-item>
      </el-form>
    </base-dialog>
  </el-col>
</template>

<script>
const BaseDialog = () => import('@/components/Dialog');

export default {
  name: 'MasterDataDialog',
  props: {
    openMasterData: Boolean,
    categoryObj: Object,
  },
  components: { BaseDialog },
  methods: {
    handleCloseMasterData() {
      this.$emit('handleCloseMasterData');
    },
  },
};
</script>

<style lang="scss" scope>
.add-master-data {
  .el-input {
    input {
      height: 47px;
    }
  }
  .el-dialog {
    width: 620px !important;
    margin-top: 20vh !important;
  }
  @media only screen and (max-width: 768px) {
    .dialog .el-dialog {
      width: calc(80% - 20px) !important;
    }
  }
  @media only screen and (max-width: 576px) {
    .dialog .el-dialog {
      width: calc(100% - 20px) !important;
    }
  }
}
</style>
