import { BaseService } from './base.service';
import { setRefreshToken, setToken } from '@/utils/storage.util';

class AuthService extends BaseService {
  async login(payload) {
    const { data } = await this.axios.post('/api/auth/login', payload);
    setToken(data.data.accessToken);
    setRefreshToken(data.data.refreshToken);

    return data;
  }

  async profile() {
    const { data } = await this.axios.get('/api/me', {
      timeout: 1000,
    });
    return data;
  }

  async logout() {
    await this.axios.post('/api/auth/logout');
    setToken('');
    setRefreshToken('');
  }

  async changePassword(payload) {
    const { data } = await this.axios.post(
      '/api/auth/change-password',
      payload
    );

    setToken(data.data.accessToken);
    setRefreshToken(data.data.refreshToken);
    return data.data;
  }
}

export const authService = new AuthService();
