exports.LOGIN = '/login';
exports.CHANGE_PASSWORD = '/change-password';
exports.EMPLOYEE = '/employees';
exports.EMPLOYEE_DETAIL = '/employees/:id';
exports.STORE = '/stores';
exports.STORE_DETAIL = '/stores/:id';
exports.PRODUCT = '/products';
exports.PRODUCT_DETAIL = '/products/:id';
exports.CATEGORY = '/categories';
exports.SUB_CATEGORY = '/categories/:id/sub-categories';
exports.HOME = '/';
exports.USER = '/users';
exports.USER_HISTORY_DETAIL = '/users/:id';
exports.PRODUCT_HISTORY = '/product-history';
exports.PRODUCT_HISTORY_DETAIL = '/product-history/:id';
