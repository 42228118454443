import { BaseService } from './base.service';

class SubcategoryService extends BaseService {
  async add(categoryId, payload) {
    const { data } = await this.axios.post(
      `/api/categories/${categoryId}`,
      payload
    );

    return data;
  }

  async getList(categoryId, params) {
    const { data } = await this.axios.get(
      `/api/categories/${categoryId}/list`,
      {
        params,
      }
    );
    return data;
  }

  async getAll(params) {
    const { data } = await this.axios.get(`/api/categories/subcategories`, {
      params,
    });
    return data;
  }

  async edit(subcategoryId, payload) {
    const { data } = await this.axios.put(
      `/api/categories/${subcategoryId}`,
      payload
    );

    return data;
  }

  async delete(subcategoryId) {
    const { data } = await this.axios.delete(
      `/api/categories/${subcategoryId}`
    );

    return data;
  }
}

export const subcategoryService = new SubcategoryService();
