import { subcategoryService } from '@/services/subcategory.service';
const initialState = {
  data: {},
  isLoading: false,
  error: {},
};
export const subcategory = {
  namespaced: true,
  state: initialState,
  getters: {
    subcategory: (state) => state.data,
    isLoadingSubcategory: (state) => state.isLoading,
    error: (state) => state.error,
  },
  mutations: {
    loading(state) {
      state.isLoading = true;
    },
    loadingSuccess(state) {
      state.isLoading = false;
    },
    setData(state, data) {
      state.data = data;
    },
    setError(state, error) {
      state.error = error;
    },
  },
  actions: {
    async getSubcategoryList({ commit }, { categoryId, params }) {
      commit('loading');
      try {
        const response = await subcategoryService.getList(categoryId, params);
        commit('setData', response);
      } catch (error) {
        commit('setError', error);
      } finally {
        commit('loadingSuccess');
      }
    },
    async getSubcategoryAll({ commit }, { params }) {
      commit('loading');
      try {
        const response = await subcategoryService.getAll(params);
        commit('setData', response);
      } catch (error) {
        commit('setError', error);
      } finally {
        commit('loadingSuccess');
      }
    },
  },
};
