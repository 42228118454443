<template>
  <div class="body-right product-mobile product">
    <div class="title-body-right">
      <h1 class="title-page">{{ $t('product.title') }}</h1>

      <div class="group-btn">
        <el-button class="btn" type="primary" @click="handleOpenAddDialog()">
          {{ $t('product.addProductBtn') }}
        </el-button>

        <el-button
          v-if="userInfo.type === ERolAdmin.ADMIN"
          class="btn"
          type="primary"
          @click="csvExport"
        >
          {{ $t('product.csvButton') }}
        </el-button>

        <el-button
          class="btn btn-search-sp"
          type="primary"
          @click="toggleSearchBlock()"
        >
          {{ $t('common.toggleMenu') }}
        </el-button>

        <el-button class="search-mobile" type="primary" @click="onSubmit">
          {{ $t('product.searchBtn') }}
        </el-button>
      </div>
    </div>

    <el-form
      :inline="true"
      :model="search"
      class="form container-search"
      :class="openSearchForm ? 'visible' : 'hidden'"
      @submit.native.prevent
      @keyup.enter.native="onSubmit()"
    >
      <el-form-item>
        <v-select
          class="form-select v-select-search search-product"
          v-model="search.productStatus"
          :searchable="false"
          :placeholder="$t('product.status')"
          :options="[
            ...[...EStatusProduct.getKeys()].map((key) => ({
              label: key,
              value: EStatusProduct.getValue(key),
            })),
          ]"
          ><template #open-indicator="{ attributes }">
            <img
              v-bind="attributes"
              src="@/assets/images/down-icon.png"
              class="down-icon"
              :class="search.productStatus && `selected`"
            /> </template
          ><template #no-options="">
            {{ $t('common.emptyText') }}
          </template></v-select
        >
      </el-form-item>

      <el-form-item>
        <v-select
          class="form-select v-select-search search-product"
          :searchable="false"
          v-model="search.placeType"
          :placeholder="$t('product.place')"
          :options="[
            ...[...EPlaceTypeProduct.getKeys()].map((key) => ({
              label: key,
              value: EPlaceTypeProduct.getValue(key),
            })),
          ]"
          ><template #open-indicator="{ attributes }">
            <img
              v-bind="attributes"
              src="@/assets/images/down-icon.png"
              class="down-icon"
              :class="search.placeType && `selected`"
            /> </template
          ><template #no-options="">
            {{ $t('common.emptyText') }}
          </template></v-select
        >
      </el-form-item>

      <el-form-item class="fullsize-input-sp">
        <el-input
          v-model="search.productName"
          :placeholder="$t('product.productName')"
          class="search-input"
        >
          <i
            slot="suffix"
            class="el-input__icon el-icon-circle-close el-input__clear"
            @click="search.productName = ''"
            v-if="search.productName"
          ></i>
        </el-input>
      </el-form-item>

      <el-form-item class="fullsize-input-sp">
        <el-autocomplete
          v-model="search.modelNumber"
          :placeholder="$t('product.modelNumber')"
          class="input"
          ref="autocomplete"
          popper-class="popper-drop-down"
          :popper-append-to-body="false"
          :fetch-suggestions="remoteModelNumberMethod"
          @select="handleSelectModelNumber"
          ><i
            slot="suffix"
            class="el-input__icon el-icon-circle-close el-input__clear"
            @click="search.modelNumber = ''"
            v-if="search.modelNumber"
          ></i
        ></el-autocomplete>
      </el-form-item>

      <el-form-item class="fullsize-input-sp">
        <el-autocomplete
          class="input"
          popper-class="popper-drop-down"
          :placeholder="$t('product.category')"
          v-model="search.categoryName"
          :popper-append-to-body="false"
          :fetch-suggestions="remoteCategoryMethod"
          @select="handleSelectCategory"
          ><i
            slot="suffix"
            class="el-input__icon el-icon-circle-close el-input__clear"
            @click="search.categoryName = ''"
            v-if="search.categoryName"
          ></i
        ></el-autocomplete>
      </el-form-item>

      <el-form-item class="fullsize-input-sp">
        <el-autocomplete
          class="input"
          popper-class="popper-drop-down"
          :placeholder="$t('product.subcategory')"
          v-model="search.subcategoryName"
          :popper-append-to-body="false"
          :fetch-suggestions="remoteSubcategoryMethod"
          @select="handleSelectSubcategory"
          ><i
            slot="suffix"
            class="el-input__icon el-icon-circle-close el-input__clear"
            @click="search.subcategoryName = ''"
            v-if="search.subcategoryName"
          ></i
        ></el-autocomplete>
      </el-form-item>

      <el-form-item class="form-date date-from" prop="fromDate">
        <el-date-picker
          type="date"
          :placeholder="$t('product.startDate')"
          v-model="search.fromDate"
          style="width: 100%"
          :editable="false"
          :picker-options="pickerBeginDateBefore"
        >
        </el-date-picker>
      </el-form-item>

      <span class="tilde">~</span>

      <el-form-item class="form-date date-to" prop="toDate">
        <el-date-picker
          type="date"
          :placeholder="$t('product.endDate')"
          v-model="search.toDate"
          style="width: 100%"
          :editable="false"
          :picker-options="pickerBeginDateAfter"
        ></el-date-picker>
      </el-form-item>

      <el-form-item class="fullsize-input-sp">
        <el-autocomplete
          class="input fullsize-input-sp"
          popper-class="popper-drop-down"
          :placeholder="$t('product.storeName')"
          v-model="search.storeName"
          :popper-append-to-body="false"
          :fetch-suggestions="remoteStoreMethod"
          @select="handleSelectStore"
          ><i
            slot="suffix"
            class="el-input__icon el-icon-circle-close el-input__clear"
            @click="search.storeName = ''"
            v-if="search.storeName"
          ></i
        ></el-autocomplete>
      </el-form-item>

      <el-form-item class="fullsize-input-sp">
        <el-autocomplete
          class="input fullsize-input-sp"
          popper-class="popper-drop-down"
          :placeholder="$t('product.manager')"
          v-model="search.employeeName"
          :popper-append-to-body="false"
          :fetch-suggestions="remoteEmployeeMethod"
          @select="handleSelectEmployee"
          ><i
            slot="suffix"
            class="el-input__icon el-icon-circle-close el-input__clear"
            @click="search.employeeName = ''"
            v-if="search.employeeName"
          ></i
        ></el-autocomplete>
      </el-form-item>

      <div class="button search-pc">
        <el-form-item>
          <el-button class="search-button" type="primary" @click="onSubmit">
            {{ $t('product.searchBtn') }}
          </el-button>
        </el-form-item>
      </div>
    </el-form>

    <el-row class="statistic">
      <div class="item-header item1">
        <div class="title-header">{{ $t('product.total') }}</div>
        <div class="number-header">{{ statistic.totalQuantity }}</div>
      </div>
      <div class="item-header item2">
        <div class="title-header">{{ $t('product.averagePurchase') }}</div>
        <div class="number-header">
          <span v-if="typeof statisticData.averageBuyPrice === 'number'">
            {{ formatNumber(statisticData.averageBuyPrice)
            }}{{ $t('common.yen') }}
          </span>
          <span v-if="statisticData.averageBuyPrice === null"> - </span>
        </div>
      </div>
      <div class="item-header item3">
        <div class="title-header">{{ $t('product.averageSales') }}</div>
        <div class="number-header">
          <span v-if="statisticData.averageSellPrice === null"> - </span>
          <span v-if="typeof statisticData.averageSellPrice === 'number'">
            {{ formatNumber(statisticData.averageSellPrice)
            }}{{ $t('common.yen') }}
          </span>
        </div>
      </div>
    </el-row>

    <table-list-product
      :dataColumn="product.data"
      :currentPage="product.currentPage"
    />

    <div class="pagination">
      <base-pagination
        :totalPage="product.total"
        @changePage="changeCurrentPage"
        :currentPage="+$route.query.page"
      >
      </base-pagination>
    </div>

    <add-product-dialog
      :openAddDialog="openAddDialog"
      @handleCloseAddDialog="handleCloseAddDialog()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { LIMIT, PAGE } from '@/constants/common';
import {
  redirectRouter,
  getDateTimeClient,
  trimValue,
  formatDate,
  formatNumber,
} from '@/helper';
import * as pageRouter from '@/constants/pageRouter';
const BasePagination = () => import('@/components/BasePagination');
import { ERolAdmin } from '@/enums/user';

const AddProductDialog = () => import('./AddProductDialog');
const TableListProduct = () => import('./TableListProduct');
import {
  EConditionTypeProduct,
  EStatusProduct,
  EPlaceTypeProduct,
} from '@/enums/product';

export default {
  name: 'ListProduct',
  components: {
    BasePagination,
    AddProductDialog,
    TableListProduct,
  },
  data() {
    return {
      ERolAdmin,
      categoryAutoComplete: {
        label: '',
        options: [],
      },
      subcategoryAutoComplete: {
        label: '',
        options: [],
      },
      storeAutoComplete: {
        label: '',
        options: [],
      },
      employeeAutoComplete: {
        label: '',
        options: [],
      },
      modelNumberAutoComplete: {
        label: '',
        options: [],
      },
      openAddDialog: false,
      search: {
        modelNumber: undefined,
        productStatus: undefined,
        placeType: undefined,
        categoryName: undefined,
        subcategoryName: undefined,
        productName: undefined,
        fromDate: undefined,
        toDate: undefined,
        storeName: undefined,
        employeeName: undefined,
        page: PAGE,
        limit: LIMIT,
      },
      formLabelWidth: '120px',
      openSearchForm: true,
      EConditionTypeProduct,
      EStatusProduct,
      EPlaceTypeProduct,
      pickerBeginDateBefore: {
        disabledDate: (time) => {
          let beginDateVal = this.search.toDate;
          if (beginDateVal) {
            return time.getTime() > beginDateVal;
          }
        },
      },
      pickerBeginDateAfter: {
        disabledDate: (time) => {
          let beginDateVal = this.search.fromDate;
          if (beginDateVal) {
            return time.getTime() < beginDateVal;
          }
        },
      },
    };
  },
  created() {
    this.search = {
      ...this.search,
      ...this.$router.currentRoute.query,
      productStatus: this.$router.currentRoute.query.productStatus && {
        label: EStatusProduct.getKey(
          +this.$router.currentRoute.query.productStatus
        ),
        value: this.$router.currentRoute.query.productStatus,
      },
      placeType: this.$router.currentRoute.query.placeType && {
        label: EPlaceTypeProduct.getKey(
          +this.$router.currentRoute.query.placeType
        ),
        value: this.$router.currentRoute.query.placeType,
      },
      page: PAGE,
      limit: LIMIT,
    };

    this.getProductList({
      ...this.search,
      productStatus:
        this.search.productStatus && this.search.productStatus.value,
      placeType: this.search.placeType && this.search.placeType.value,
    });
    this.getProductStatistic({
      params: {
        ...this.search,
        limit: undefined,
        page: undefined,
        productStatus:
          this.search.productStatus && this.search.productStatus.value,
        placeType: this.search.placeType && this.search.placeType.value,
      },
    });
  },
  computed: {
    ...mapGetters('product', [
      'product',
      'statisticData',
      'modelNumber',
      'modelNumber',
    ]),
    ...mapGetters('category', ['category']),
    ...mapGetters('subcategory', ['subcategory']),
    ...mapGetters('store', ['store']),
    ...mapGetters('employee', ['employee']),
    ...mapGetters('user', ['userInfo']),
    mapCategoryData() {
      return this.category.data.map((item) => ({
        id: item.id,
        value: item.name,
      }));
    },
    mapSubcategoryData() {
      return this.subcategory.data.map((item) => ({
        id: item.id,
        value: item.name,
      }));
    },
    mapStoreData() {
      return this.store.data.map((item) => ({
        id: item.id,
        value: item.name,
      }));
    },
    mapEmployeeData() {
      return this.employee.data.map((item) => ({
        id: item.id,
        value: item.name,
      }));
    },
    mapModelNumberData() {
      return this.modelNumber.data.map((item) => ({
        id: item.id,
        value: item.modelNumber,
      }));
    },
    statistic() {
      return {
        totalQuantity: formatNumber(this.statisticData.totalQuantity),
        averageBuyPrice:
          this.statisticData.averageBuyPrice !== null
            ? formatNumber(this.statisticData.averageBuyPrice)
            : null,
        averageSellPrice:
          this.statisticData.averageSellPrice !== null
            ? formatNumber(this.statisticData.averageSellPrice)
            : null,
      };
    },
  },
  methods: {
    ...mapActions('category', ['getCategoryList']),
    ...mapActions('subcategory', ['getSubcategoryAll']),
    ...mapActions('store', ['getStoreList']),
    ...mapActions('employee', ['getEmployeeList']),
    ...mapActions('product', [
      'getProductList',
      'exportCsv',
      'getProductStatistic',
      'getModelNumber',
    ]),
    formatNumber,
    handleOpenAddDialog() {
      this.openAddDialog = true;
    },
    handleCloseAddDialog() {
      this.openAddDialog = false;
    },
    async onSubmit() {
      this.search = {
        ...trimValue({ ...this.search }),
        page: PAGE,
        limit: LIMIT,
        fromDate: this.search.fromDate
          ? formatDate(this.search.fromDate)
          : undefined,
        toDate: this.search.toDate ? formatDate(this.search.toDate) : undefined,
      };

      Promise.all([
        this.getProductList({
          ...this.search,
          productStatus:
            this.search.productStatus && this.search.productStatus.value,
          placeType: this.search.placeType && this.search.placeType.value,
        }),
        this.getProductStatistic({
          params: {
            ...this.search,
            page: undefined,
            limit: undefined,
            productStatus:
              this.search.productStatus && this.search.productStatus.value,
            placeType: this.search.placeType && this.search.placeType.value,
          },
        }),
      ]);

      redirectRouter({
        path: pageRouter.PRODUCT,
        query: {
          ...this.search,
          productStatus:
            this.search.productStatus && this.search.productStatus.value,
          placeType: this.search.placeType && this.search.placeType.value,
        },
      });
    },
    async csvExport() {
      const options = {
        ...this.search,
        ...this.$router.currentRoute.query,
        page: undefined,
        limit: undefined,
      };
      const data = await this.exportCsv(options);
      const url = window.URL.createObjectURL(
        new Blob([data], { type: `text/csv` })
      );
      const link = document.createElement('a');
      link.href = url;
      link.download = `CSV出力_${getDateTimeClient()}.csv`;
      link.click();
      window.URL.revokeObjectURL(data);
      link.remove();
    },
    toggleSearchBlock() {
      this.openSearchForm = !this.openSearchForm;
    },
    changeCurrentPage(e) {
      this.search = {
        ...this.$router.currentRoute.query,
        productStatus: this.$router.currentRoute.query.productStatus && {
          label: EStatusProduct.getKey(
            +this.$router.currentRoute.query.productStatus
          ),
          value: this.$router.currentRoute.query.productStatus,
        },
        placeType: this.$router.currentRoute.query.placeType && {
          label: EPlaceTypeProduct.getKey(
            +this.$router.currentRoute.query.placeType
          ),
          value: this.$router.currentRoute.query.placeType,
        },
        page: e.page,
        limit: LIMIT,
      };

      this.getProductList({
        ...this.search,
        productStatus:
          this.search.productStatus && this.search.productStatus.value,
        placeType: this.search.placeType && this.search.placeType.value,
      });

      redirectRouter({
        path: pageRouter.PRODUCT,
        query: {
          ...this.search,
          productStatus:
            this.search.productStatus && this.search.productStatus.value,
          placeType: this.search.placeType && this.search.placeType.value,
          page: this.search.page || undefined,
        },
      });
    },

    async remoteCategoryMethod(query, cb) {
      await this.getCategoryList({
        name: query,
      });
      this.categoryAutoComplete.options = this.mapCategoryData || [];

      cb(this.categoryAutoComplete.options);
    },

    handleSelectCategory(item) {
      this.search.categoryName = item.value;
    },

    async remoteSubcategoryMethod(query, cb) {
      await this.getSubcategoryAll({
        params: {
          name: query,
        },
      });
      this.subcategoryAutoComplete.options = this.mapSubcategoryData || [];

      cb(this.subcategoryAutoComplete.options);
    },

    handleSelectSubcategory(item) {
      this.search.subcategoryName = item.value;
    },

    async remoteStoreMethod(query, cb) {
      await this.getStoreList({
        freeText: query,
        onlyName: 1,
      });
      this.storeAutoComplete.options = this.mapStoreData || [];

      cb(this.storeAutoComplete.options);
    },

    handleSelectStore(item) {
      this.search.storeName = item.value;
    },

    async remoteEmployeeMethod(query, cb) {
      await this.getEmployeeList({
        name: query,
      });
      this.employeeAutoComplete.options = this.mapEmployeeData || [];

      cb(this.employeeAutoComplete.options);
    },

    handleSelectEmployee(item) {
      this.search.employeeName = item.value;
    },

    async remoteModelNumberMethod(query, cb) {
      await this.getModelNumber({
        modelNumber: query,
        isSearch: 1,
      });
      this.modelNumberAutoComplete.options = this.mapModelNumberData || [];

      cb(this.modelNumberAutoComplete.options);
    },

    handleSelectModelNumber(item) {
      this.search.modelNumber = item.value;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/common.scss';
@import './styles.module.scss';

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  _::-webkit-full-page-media,
  _:future,
  :root,
  .vs__search {
    padding-left: 14px;
  }
}
</style>
