import Enum from './Enum';
import i18n from '@/plugins/i18n';

export const EConditionTypeProduct = new Enum({
  S: 1,
  A: 2,
  B: 3,
  C: 4,
  D: 5,
  [i18n.t('enum.noUse')]: 6,
});

export const EStatusProduct = new Enum({
  [i18n.t('enum.success')]: 1,
  [i18n.t('enum.unsuccess')]: 2,
});

export const EPlaceTypeProduct = new Enum({
  [i18n.t('enum.store')]: 1,
  [i18n.t('enum.home')]: 2,
  [i18n.t('enum.market')]: 3,
});

export const EDeleteStatus = new Enum({
  [i18n.t('enum.all')]: undefined,
  [i18n.t('enum.deleted')]: 1,
  [i18n.t('enum.noDeleted')]: 2,
});
