export default {
  message: {
    hello: 'こんにちは、世界',
  },
  common: {
    yesBtn: 'はい',
    noBtn: 'いいえ',
    addSuccess: '追加しました。',
    editSuccess: '編集しました。',
    deleteSuccess: '削除しました。',
    backToEmployee: '従業員管理へ戻る',
    employeeDetail: 'さん',
    emptyText: 'データなし',
    storeDetail: '店',
    action: '操作',
    toggleMenu: '検索メニュー',
    yen: '円',
  },
  validate: {
    require: '※未記入です。',
    sizePassword: '※8~32文字以内で入力してください。',
    haftWithPassword: '※半角英数文字でパスワードを入力してください!',
    dontMatch: '※パスワードが一致しておりません。',
    maxLength: '※30文 字以下で入力してください！',
    emailInvalidate: '※入力したメールアドレスの形式が違います。',
    emailExist: '※メールアドレスが既に存在します。',
    phoneInvalidate: '※電話番号は10桁または11桁で入力してください！',
    maxLengthAddress: '※100文 字以下で入力してください！',
    specialEmail: '※特殊文字を入力しないでください！',
    spacePassword: '※空白を使わないでください！',
    startZero: '※電話番号は”0”から入力してください！',
    requireHaftWidth: '※半角英数文字でメールアドレスを入力してください!',
    phoneFormat: '※半角数字を入力してください。',
    max255: '※255文字以内で入力してください。',
    negativeNumber: '※正の整数を入力してください。',
    decimalNumber: '※少数を入力しないでください。',
    specialNumber: '※特殊文字を入力しないでください！',
    formatNumber: '※半角数字を入力してください。',
    formatYear: '※YYYY形式で入力してください。',
  },
  error: {
    emailPasswordWrong:
      'ユーザー名またはパスワードの入力を間違えました。お手数おかけますが、もう一度入力してください！',
    passWrong: '現在のパスワードが間違っています。',
    namesakeCategory: '既に存在している項目名です。',
    namesake: '既に存在しているユーザー名です。',
  },
  login: {
    email: 'ユーザー名',
    password: 'パスワード',
    btnLogin: 'ログイン',
  },
  logout: {
    title: 'ログアウト',
    logoutSuccess: 'ログアウトしました。',
  },
  store: {
    title: '店舗一覧',
    addStoreBtn: '+追加',
    searchBtn: '検索',
    searchPlaceholder: 'フリーワードで検索',
    total: '合計',
    id: 'ID',
    name: '店舗名',
    email: 'メールアドレス',
    phone: '電話番号',
    address: '住所',
    addStore: '店舗追加',
    confirmBtn: '追加',
    cancelBtn: 'キャンセル',
    textConfirmDeleteStore: 'この店舗を削除しますか?',
    editBtn: '編集',
    editStore: '店舗編集',
    placeholderPhone: '例：0x-xxxx-xxxxまたは0xx-xxxx-xxxx',
  },
  screenChangePassword: {
    changePassword: 'パスワード変更',
    changeSuccess: 'パスワードが変更されました。',
  },
  changePassword: {
    password: '現在のパスワード',
    passwordNew: '新しいパスワード',
    confirmPassword: '新しいパスワード（再入力）',
    submitButton: '保存',
  },
  menu: {
    employee: '従業員管理',
    product: '商品管理',
    productList: '商品一覧',
    store: '店舗管理',
    masterData: 'マスターデーター管理',
    other: 'その他',
    history: '管理',
    user: 'ユーザー管理',
    productHistory: '商品履歴管理',
  },
  employee: {
    title: '従業員一覧',
    addEmployee: '従業員追加',
    search: '検索',
    name: '名前',
    storeName: '店舗名',
    total: '合計',
    email: 'メールアドレス',
    phone: '電話番号',
    jobTitle: '職務',
    store: '店頭',
    placeholderStore: '店頭名で検索',
    confirmBtn: '追加',
    cancelBtn: 'キャンセル',
    editBtn: '編集',
    editEmployee: '従業員編集',
    placeholderPhone: '例：0x-xxxx-xxxxまたは0xx-xxxx-xxxx',
    confirmDelete: 'この従業員を削除しますか?',
    labelStore: '店舗',
    searchStore: '店舗で検索',
    searchName: '名前で検索',
    addButton: '＋追加',
    storePlaceholder: '検索',
  },
  product: {
    addTitle: '商品追加',
    searchButton: '検索',
    placeholderProductName: '商品名で検索',
    placeholderCategory: '型番で検索',
    status: '状態',
    place: '場所',
    modelNumber: '型番',
    productName: '商品名',
    category: '大項目',
    subcategory: '小項目',
    conditionType: '程度',
    purchasePrice: '買取金額',
    price: '販売金額',
    quantity: '数量',
    totalPurchase: '買取総額',
    marker: 'メーカー',
    years: '年式',
    note: 'メモ',
    store: '店舗',
    manager: '担当者',
    date: '日付',
    operate: '操作',
    title: '商品一覧',
    addProductBtn: '+追加',
    csvButton: 'CSV 出力',
    startDate: '開始日',
    endDate: '終了日',
    storeName: '店舗名',
    searchBtn: '検索',
    total: '商品合計',
    averagePurchase: '買取金額平均',
    averageSales: '販売金額平均',
    cancelBtn: 'キャンセル',
    confirmBtn: '追加',
    deleteTitle: 'この商品を削除しますか？',
    editTitle: '商品編集',
    editBtn: '編集',
  },
  category: {
    title: '大項目',
    total: '合計',
    placeholderName: '項目名で検索',
    searchBtn: '検索',
    addCategoryBtn: '+追加',
    name: '項目名',
    addTitle: '項目追加',
    editTitle: '項目編集',
    deleteTitle: 'この項目を削除しますか？',
    editBtn: '編集',
  },
  enum: {
    noUse: 'ジャンク',
    success: '成立',
    unsuccess: '不成立',
    store: '店買',
    home: '出張',
    market: '市場',
    login: 'ログイン',
    logout: 'ログアウト',
    add: '追加',
    edit: '編集',
    delete: '削除',
    all: 'すべて',
    deleted: '削除済み',
    noDeleted: '未削除',
  },
  user: {
    title: 'ユーザー一覧',
    addBtn: '+追加',
    searchBtn: '検索',
    placeholderName: 'ユーザー名で検索',
    total: '合計',
    username: 'ユーザー名',
    name: 'お名前',
    addUser: 'ユーザー追加',
    passwordSetting: 'パスワード設定',
    password: 'パスワード',
    confirmPassword: 'パスワード再確認',
    cancelBtn: 'キャンセル',
    save: '追加',
    editUser: 'ユーザー編集',
    passwordNew: '新しいパスワード',
    confirmNewPassword1: '新しいパスワード',
    confirmNewPassword2: '（再確認）',
    editBtn: '編集',
    deleteUser: 'このユーザーを削除しますか？',
    historyUser: '操作ログ管理',
    osName: '端末名',
    ipAddress: 'IPアドレス',
    timeDate: '日付',
    screen: '画面',
    operate: '操作',
    detail: '詳細',
    startDate: '開始日',
    endDate: '終了日',
    productManagement: '商品管理',
    masterDataManagement: 'マスターデーター管理',
    actionType: '操作',
    searchPlaceholder: 'フリーワードで検索',
  },
  productHistory: {
    title: '商品履歴',
    search: '絞り込み',
    username: 'ユーザー名',
    name: 'お名前',
    operate: '操作',
    date: '日付',
    startDate: '開始日',
    endDate: '終了日',
  },
};
