<template>
  <base-dialog
    :title="title"
    class="dialog-delete"
    :openDialog="openDeleteDialog"
  >
    <h3 class="confirm-delete">{{ content }}</h3>
    <span class="dialog-footer dialog-delete">
      <el-button class="btn btn--cancel" @click="handleCloseDialog()">
        {{ $t('common.noBtn') }}
      </el-button>
      <el-button
        class="btn btn--submit"
        type="primary"
        @click="handleConfirm()"
        :disabled="deleteLoading"
        >{{ $t('common.yesBtn') }}</el-button
      >
    </span>
  </base-dialog>
</template>

<script>
const BaseDialog = () => import('@/components/Dialog');

export default {
  name: 'ModalDelete',
  components: { BaseDialog },
  props: {
    title: String,
    content: String,
    openDeleteDialog: Boolean,
    deleteLoading: Boolean,
  },
  methods: {
    handleCloseDialog() {
      this.$emit('handleCloseDialog');
    },
    handleConfirm() {
      this.$emit('handleConfirm');
    },
  },
};
</script>

<style lang="scss" scope>
.confirm-delete {
  text-align: center;
  padding-bottom: 20px;
  word-break: break-word;
}
.dialog-delete {
  .el-dialog {
    width: 500px !important;

    .dialog-delete {
      justify-content: space-around !important;
    }

    @media only screen and (max-width: 576px) {
      width: calc(100% - 20px) !important;
    }
  }
}
</style>
