<template>
  <el-col :sm="12">
    <base-dialog :title="$t('user.detail')" :openDialog="openProduct">
      <el-form v-if="product" label-position="top" class="add-modal">
        <el-row :gutter="50" class="row-input">
          <el-col :xs="24" :sm="12">
            <el-form-item
              v-if="product.subcategory"
              :label="$t('product.category')"
            >
              <el-input
                readonly
                class="input"
                :value="product.subcategory.category.name"
                autocomplete="off"
              >
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12">
            <el-form-item
              v-if="product.subcategory"
              :label="$t('product.subcategory')"
            >
              <el-input
                readonly
                class="input"
                :value="product.subcategory.name"
                autocomplete="off"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="50" class="row-input">
          <el-col :xs="24" :sm="12">
            <el-form-item :label="$t('product.modelNumber')">
              <el-input
                readonly
                class="input"
                :value="product.modelNumber"
                autocomplete="off"
              >
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12">
            <el-form-item :label="$t('product.productName')">
              <el-input
                readonly
                class="input"
                :value="product.name"
                autocomplete="off"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="50" class="row-input">
          <el-col :xs="24" :sm="12">
            <el-form-item :label="$t('product.conditionType')">
              <el-input
                readonly
                class="input"
                :value="EConditionTypeProduct.getTitle(product.conditionType)"
                autocomplete="off"
              >
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12">
            <el-form-item :label="$t('product.quantity')">
              <el-input
                readonly
                class="input"
                :value="product.quantity"
                autocomplete="off"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="50" class="row-input">
          <el-col :xs="24" :sm="12">
            <el-form-item :label="$t('product.marker')">
              <el-input
                readonly
                class="input"
                :value="product.marker"
                autocomplete="off"
              >
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12">
            <el-form-item :label="$t('product.years')">
              <el-input
                readonly
                class="input"
                :value="product.yearOfManufacture"
                autocomplete="off"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="50" class="row-input">
          <el-col :xs="24" :sm="12">
            <el-form-item :label="$t('product.purchasePrice')">
              <el-input
                readonly
                class="input"
                :value="product.buyPrice"
                autocomplete="off"
              >
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12">
            <el-form-item :label="$t('product.price')">
              <el-input
                readonly
                class="input"
                :value="product.sellPrice"
                autocomplete="off"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="50" class="row-input">
          <el-col :xs="24" :sm="12">
            <el-form-item v-if="product.store" :label="$t('product.store')">
              <el-input
                readonly
                class="input"
                :value="product.store.name"
                autocomplete="off"
              >
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12">
            <el-form-item
              v-if="product.employee"
              :label="$t('product.manager')"
            >
              <el-input
                readonly
                class="input"
                :value="product.employee.name"
                autocomplete="off"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="50" class="row-input">
          <el-col :xs="24" :sm="12">
            <el-form-item :label="$t('product.status')">
              <el-input
                readonly
                class="input"
                :value="EStatusProduct.getTitle(product.status)"
                autocomplete="off"
              >
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12">
            <el-form-item :label="$t('product.place')">
              <el-input
                readonly
                class="input"
                :value="EPlaceTypeProduct.getTitle(product.placeType)"
                autocomplete="off"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="50" class="row-input">
          <el-col :xs="24" :sm="12">
            <el-form-item :label="$t('product.note')">
              <el-input
                readonly
                class="input"
                :value="product.note"
                autocomplete="off"
              >
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12">
            <el-form-item :label="$t('product.date')">
              <el-input
                readonly
                class="input"
                :value="product.date"
                autocomplete="off"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <span class="dialog-footer">
            <el-button
              class="btn btn--cancel"
              @click="handleCloseProductDetail()"
            >
              OK
            </el-button>
          </span>
        </el-form-item>
      </el-form>
    </base-dialog>
  </el-col>
</template>

<script>
import {} from '@/helper';
import {
  EConditionTypeProduct,
  EStatusProduct,
  EPlaceTypeProduct,
} from '@/enums/product';

const BaseDialog = () => import('@/components/Dialog');

export default {
  name: 'ProducDataDetailDialog',
  props: {
    openProduct: Boolean,
    product: Object,
  },
  data() {
    return {
      EConditionTypeProduct,
      EStatusProduct,
      EPlaceTypeProduct,
    };
  },
  components: { BaseDialog },
  methods: {
    handleCloseProductDetail() {
      this.$emit('handleCloseProductDetail');
    },
  },
};
</script>

<style lang="scss" scope>
.add-modal {
  .el-autocomplete {
    width: 100%;
  }

  .el-autocomplete-suggestion {
    margin: 0 !important;
  }
}
</style>
