<template>
  <div
    id="table-component"
    ref="table"
    class="table-component height-60 bg-white"
  >
    <table
      class="table min-w-full"
      @scroll="handleScroll"
      :class="{ 'scroll-end': checkScroll }"
    >
      <template>
        <thead>
          <tr
            class="table-header"
            :class="dataColumn.length === 0 ? 'header-result-empty' : ''"
          >
            <slot name="header" />
          </tr>
        </thead>
        <tbody class="row">
          <tr
            class="table-row"
            v-for="(item, i) in dataColumn"
            :key="i"
            :class="{ 'deleted-color': isDeleted && item.deletedAt }"
          >
            <slot name="body" :rowData="item" :index="i" />
          </tr>
        </tbody>
      </template>
    </table>
    <div v-if="dataColumn.length === 0" class="content-result-empty">
      {{ $t('common.emptyText') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableComponent',
  props: {
    dataColumn: {
      type: Array,
      default: () => [],
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkScroll: false,
    };
  },
  mounted() {
    document
      .querySelector('.table-component')
      .addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll(e) {
      if (e.target.clientWidth + e.target.scrollLeft >= e.target.scrollWidth) {
        this.checkScroll = true;
      } else {
        this.checkScroll = false;
      }
    },
  },
};
</script>
