<template>
  <el-pagination
    layout="prev, pager, next"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-size="LIMIT"
    :total="totalPage"
    background
    class="paginate"
  >
  </el-pagination>
</template>

<script>
import { LIMIT } from '@/constants/common';

export default {
  name: 'BasePagination',
  props: {
    pageSize: Number,
    totalPage: Number,
    currentPage: Number,
  },
  data() {
    return { LIMIT };
  },
  methods: {
    handleCurrentChange(val) {
      const tableComponent = document.querySelectorAll('#table-component');
      tableComponent[0].scrollTo(0, 0);

      this.$emit('changePage', { page: val });
    },
  },
};
</script>
<style lang="scss">
@import './styles.module.scss';
</style>
